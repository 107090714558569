// This file is automatically generated by Vocab.
// To make changes update translation.json files directly.

import type { FormatXMLElementFn } from '@vocab/core';
import { createLanguage, createTranslationFile } from '@vocab/core/runtime';

const translations = createTranslationFile<
  | 'en'
  | 'th'
  | 'id'
  | 'zh'
  | 'en-AU'
  | 'en-HK'
  | 'en-ID'
  | 'en-MY'
  | 'en-NZ'
  | 'en-PH'
  | 'en-SG'
  | 'en-TH'
  | 'th-TH'
  | 'id-ID'
  | 'zh-HK'
  | 'en-PSEUDO',
  {
    'Select filters to uncover deeper insights about candidates in country.': (values: {
      country: string;
    }) => string;
    'Explore drivers by': () =>
      | 'Explore drivers by'
      | 'ค้นหาปัจจัยตาม'
      | 'Cari tahu faktor berdasarkan'
      | '[Ëꭕƥƚöööřẽẽẽ ƌřìììṽẽẽẽřš ßýýý]';
    'Select at least one option': () =>
      | 'Select at least one option'
      | 'เลือกอย่างน้อย 1 ตัวเลือก'
      | 'Pilih setidaknya satu opsi'
      | '[Šẽẽẽƚẽẽẽçṯ ăăăṯ ƚẽẽẽăăăšṯ öööกี้ẽẽẽ öööƥṯìììöööกี้]';
    Share: () => 'Share' | 'แชร์บน' | 'Bagikan' | '[Šḩăăřẽẽ]';
    'Share on Facebook': () =>
      | 'Share on Facebook'
      | 'แชร์บน Facebook'
      | 'Bagikan di Facebook'
      | '[Šḩăăřẽẽ ööกี้ Ƒăăçẽẽßööööķ]';
    'Share on X': () =>
      | 'Share on X'
      | 'แชร์บน X'
      | 'Bagikan di X'
      | '[Šḩăăăřẽẽẽ öööกี้ X̂]';
    'Share on LinkedIn': () =>
      | 'Share on LinkedIn'
      | 'แชร์บน LinkedIn'
      | 'Bagikan di LinkedIn'
      | '[Šḩăăăřẽẽẽ öööกี้ Łìììกี้ķẽẽẽƌÏกี้]';
    'Copy Link': () =>
      | 'Copy Link'
      | 'คัดลองลิงก์'
      | 'Salin tautan'
      | '[Çöööƥýýý Łìììกี้ķ]';
    'Link copied to clipboard': () =>
      | 'Link copied to clipboard'
      | 'คัดลอกลิงก์'
      | 'Tautan disalin ke papan clip'
      | '[Łìììกี้ķ çöööƥìììẽẽẽƌ ṯööö çƚìììƥßöööăăăřƌ]';
    'Clipboard access not available': () =>
      | 'Clipboard access not available'
      | 'ไม่สามารถเข้าถึงคลิปบอร์ดได้'
      | 'Akses ke papan klik tidak tersedia'
      | '[Çƚìììƥßöööăăăřƌ ăăăççẽẽẽšš กี้öööṯ ăăăṽăăăìììƚăăăßƚẽẽẽ]';
    'Download PDF': () =>
      | 'Download PDF'
      | 'ดาวน์โหลด PDF'
      | 'Unduh PDF'
      | '[Ḋöööŵกี้ƚöööăăăƌ ƤḊƑ]';
    'Showing results from': () =>
      | 'Showing results from'
      | 'แสดงผลลัพธ์จาก'
      | 'Menampilkan Hasil dari'
      | '[Šḩöööŵìììกี้ģ řẽẽẽšǚǚǚƚṯš ƒřöööm̂]';
    'All respondents': () =>
      | 'All respondents'
      | 'ผู้ตอบแบบสอบถามทุกคน'
      | 'Semua responden'
      | '[Ậƚƚ řẽẽẽšƥöööกี้ƌẽẽẽกี้ṯš]';
    'Showing results that match': () =>
      | 'Showing results that match'
      | 'แสดงผลลัพธ์ที่ตรงความต้องการ'
      | 'Menampilkan hasil yang cocok'
      | '[Šḩöööŵìììกี้ģ řẽẽẽšǚǚǚƚṯš ṯḩăăăṯ m̂ăăăṯçḩ]';
    'Results are based on': () =>
      | 'Results are based on'
      | 'ผลลัพธ์อาจขึ้นอยู่กับผู้ตอบแบบสอบถาม'
      | 'Hasil berdasarkan'
      | '[Řẽẽẽšǚǚǚƚṯš ăăăřẽẽẽ ßăăăšẽẽẽƌ öööกี้]';
    'respondents from a total of': () =>
      | 'respondents from a total of'
      | 'คน จากผู้หางานทั้งหมด'
      | 'responden dari total'
      | '[řẽẽẽšƥöööกี้ƌẽẽẽกี้ṯš ƒřöööm̂ ăăă ṯöööṯăăăƚ öööƒ]';
    'candidates surveyed': () =>
      | 'candidates surveyed'
      | 'คนที่เข้าร่วมตอบแบบสอบถามในช่วง'
      | 'kandidat yang disurvei dalam'
      | '[çăăกี้ƌììƌăăṯẽẽš šǚǚřṽẽẽýýẽẽƌ]';
    'Drivers that attract these candidates': () =>
      | 'Explore Key Talent Trends and Preferences'
      | 'เข้าใจถึงแนวโน้มและปัจจัยสำคัญที่มีผลต่อผู้หางาน'
      | 'Pelajari Kunci Tentang Tren Kandidat Terkini dan Preferensinya'
      | '[Ëꭕƥƚöööřẽẽẽ Ḱẽẽẽýýý Ṯăăăƚẽẽẽกี้ṯ Ṯřẽẽẽกี้ƌš ăăăกี้ƌ Ƥřẽẽẽƒẽẽẽřẽẽẽกี้çẽẽẽš]';
    'About these candidates': () =>
      | 'Candidate Demographics and Behavior'
      | 'ข้อมูลประชากรและพฤติกรรมของผู้หางาน'
      | 'Demografi dan Tipe Kandidat'
      | '[Çăăăกี้ƌìììƌăăăṯẽẽẽ Ḋẽẽẽm̂öööģřăăăƥḩìììçš ăăăกี้ƌ ẞẽẽẽḩăăăṽìììöööř]';
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.": () =>
      | "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations."
      | "ตัวเลขเปอร์เซ็นต์นี้แสดงถึงลำดับความสำคัญของแต่ละปัจจัยที่มีอิทธิพล ปัจจัยทั้งหมดอาจมีความ 'สำคัญ' แต่สิ่งนี้จะช่วยให้คุณเข้าใจการจัดลำดับความสำคัญของแต่ละปัจจัยจากผู้ตอบแบบสอบถามที่พวกเขามุ่งเน้นในประกาศงานและการสื่อสาร"
      | "Persentase ini adalah cara kandidat memberi peringkat faktor pendorong berdasarkan tingkat kepentingan. Semua faktor pendorong bisa jadi 'penting', tetapi ini membantu Anda memprioritaskan faktor mana untuk difokuskan dalam iklan pekerjaan atau percakapan."
      | "[Ṯḩìììš % ìììš ḩöööŵ çăăăกี้ƌìììƌăăăṯẽẽẽš řăăăกี้ķ ṯḩẽẽẽ ƌřìììṽẽẽẽř öööกี้ ìììm̂ƥöööřṯăăăกี้çẽẽẽ. Ậƚƚ ƌřìììṽẽẽẽřš çöööǚǚǚƚƌ ßẽẽẽ 'ìììm̂ƥöööřṯăăăกี้ṯ' ßǚǚǚṯ ṯḩìììš ḩẽẽẽƚƥš ýýýöööǚǚǚ ƥřìììöööřìììṯìììšẽẽẽ ṯḩẽẽẽ ƌřìììṽẽẽẽřš ṯööö ƒöööçǚǚǚš öööกี้ ìììกี้ ĵöööß ăăăƌš öööř çöööกี้ṽẽẽẽřšăăăṯìììöööกี้š.]";
    "We'll show this data when we have more responses from people who match your search.": () =>
      | "We'll show this data when we have more responses from people who match your search."
      | 'เราจะแสดงข้อมูลนี้หากมีข้อมูลเพิ่มเติมจากผู้หางานที่ตรงความต้องการของคุณ'
      | 'Kami akan menampilkan data ini ketika kami memiliki jumlah respon yang cukup dari orang yang sesuai dengan pencarian Anda.'
      | "[Ŵẽẽẽ'ƚƚ šḩöööŵ ṯḩìììš ƌăăăṯăăă ŵḩẽẽẽกี้ ŵẽẽẽ ḩăăăṽẽẽẽ m̂öööřẽẽẽ řẽẽẽšƥöööกี้šẽẽẽš ƒřöööm̂ ƥẽẽẽöööƥƚẽẽẽ ŵḩööö m̂ăăăṯçḩ ýýýöööǚǚǚř šẽẽẽăăăřçḩ.]";
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.": () =>
      | "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do."
      | 'เรากำลังรวบรวมคำตอบจากผู้หางานเพื่อให้มั่นใจว่าอะไรคือสิ่งที่ดึงดูดใจผู้สมัครในการสมัครงานตำแหน่งนั้นๆ เราจะอัปเดตข้อมูลเมื่อเราดำเนินการเสร็จสิ้น'
      | 'Kami berusaha mengumpulkan respon yang cukup dari para kandidat ini untuk berbagi apa yang membuat mereka tertarik pada suatu posisi dengan percaya diri. Kami akan memperbarui data ketika kami berhasil mengumpulkannya.'
      | "[Ŵẽẽẽ’řẽẽẽ ŵöööřķìììกี้ģ öööกี้ ģăăăṯḩẽẽẽřìììกี้ģ ẽẽẽกี้öööǚǚǚģḩ řẽẽẽšƥöööกี้šẽẽẽš ƒřöööm̂ ṯḩẽẽẽšẽẽẽ çăăăกี้ƌìììƌăăăṯẽẽẽš ṯööö çöööกี้ƒìììƌẽẽẽกี้ṯƚýýý šḩăăăřẽẽẽ ŵḩăăăṯ ăăăṯṯřăăăçṯš ṯḩẽẽẽm̂ ṯööö ăăă řöööƚẽẽẽ. Ŵẽẽẽ'ƚƚ ǚǚǚƥƌăăăṯẽẽẽ ṯḩẽẽẽ ƌăăăṯăăă ŵḩẽẽẽกี้ ŵẽẽẽ ƌööö.]";
    'In the meantime, you can adjust your filters to try a broader search.': () =>
      | 'In the meantime, you can adjust your filters to try a broader search.'
      | 'ในขณะเดียวกัน คุณสามารถปรับแต่งการคัดกรองเพื่อทำให้การค้นหากว้างขึ้น'
      | 'Sementara itu, Anda bisa menyesuaikan filter Anda untuk mencoba pencarian yang lebih luas.'
      | '[Ïกี้ ṯḩẽẽẽ m̂ẽẽẽăăăกี้ṯìììm̂ẽẽẽ, ýýýöööǚǚǚ çăăăกี้ ăăăƌĵǚǚǚšṯ ýýýöööǚǚǚř ƒìììƚṯẽẽẽřš ṯööö ṯřýýý ăăă ßřöööăăăƌẽẽẽř šẽẽẽăăăřçḩ.]';
    'These are the drivers candidates consider when looking at roles.': () =>
      | 'These are the drivers candidates consider when looking at roles.'
      | 'นี่คือปัจจัยที่ผู้สมัครงานพิจารณาเมื่อมองหางานใหม่'
      | 'Ini adalah faktor pendorong yang dipertimbangkan kandidat saat melihat posisi.'
      | '[Ṯḩẽẽẽšẽẽẽ ăăăřẽẽẽ ṯḩẽẽẽ ƌřìììṽẽẽẽřš çăăăกี้ƌìììƌăăăṯẽẽẽš çöööกี้šìììƌẽẽẽř ŵḩẽẽẽกี้ ƚööööööķìììกี้ģ ăăăṯ řöööƚẽẽẽš.]';
    'Drivers are ranked with the most important driver at the top.': () =>
      | 'Drivers are ranked with the most important driver at the top.'
      | 'ปัจจัยเหล่านี้จะจัดเรียงตามลำดับความสำคัญต่อผู้หางานโดยปัจจัยที่มีอิทธิพลต่อผู้หางานมากที่สุดจะอยู่ด้านบน'
      | 'Faktor pendorong diurutkan dari yang terpenting di bagian atas.'
      | '[Ḋřìììṽẽẽẽřš ăăăřẽẽẽ řăăăกี้ķẽẽẽƌ ŵìììṯḩ ṯḩẽẽẽ m̂öööšṯ ìììm̂ƥöööřṯăăăกี้ṯ ƌřìììṽẽẽẽř ăăăṯ ṯḩẽẽẽ ṯöööƥ.]';
    'Expand each driver for more detail.': () =>
      | 'Expand each driver for more detail.'
      | 'คุณสามารถคลิกแสดงเนื้อหาเพื่อดูรายละเอียดเพิ่มเติม'
      | 'Perluas setiap faktor untuk detail lebih lanjut.'
      | '[Ëꭕƥăăăกี้ƌ ẽẽẽăăăçḩ ƌřìììṽẽẽẽř ƒöööř m̂öööřẽẽẽ ƌẽẽẽṯăăăìììƚ.]';
    "No data available yet for selected filters. We'll show this when we have enough responses.": (values: {
      filter: string;
    }) => string;
    'Sample size for selected filters is small, so there is greater margin of error.': <
      T = string,
    >(values: {
      filter: string;
      u: FormatXMLElementFn<T>;
    }) => ReturnType<FormatXMLElementFn<T>>;
    'When writing job ads': () =>
      | "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. "
      | 'เมื่อเขียนประกาศงาน ให้มุ่งเน้นไปที่ปัจจัยที่จำเป็นต้องมีและปัจจัยที่ดีถ้ามี เพื่อสร้างความโดดเด่นเหนือคู่แข่ง อย่าเสียเวลาไปกับปัจจัยที่ผู้หางานไม่ได้ให้ความสนใจและปัจจัยที่อาจนำไปสู่การปฏิเสธงาน'
      | 'Saat menulis iklan lowongan kerja, fokuslah pada faktor-faktor pendorong utama yang menarik kandidat dan yang membedakan Anda dengan kompetitor. Jangan fokus pada faktor pendorong yang kurang menarik. '
      | "[Ŵḩẽẽẽกี้ ŵřìììṯìììกี้ģ ĵöööß ăăăƌš, ƒöööçǚǚǚš öööกี้ ƌẽẽẽƚìììģḩṯẽẽẽƌ-ìììƒ ăăăกี้ƌ m̂ǚǚǚšṯ-ḩăăăṽẽẽẽ ƌřìììṽẽẽẽřš ṯḩăăăṯ ƌìììƒƒẽẽẽřẽẽẽกี้ṯìììăăăṯẽẽẽ ýýýöööǚǚǚ ƒřöööm̂ ýýýöööǚǚǚř çöööm̂ƥẽẽẽṯìììṯöööřš. Ḋöööกี้'ṯ ŵăăăšṯẽẽẽ šƥăăăçẽẽẽ öööกี้ กี้öööṯ-ßöööṯḩẽẽẽřẽẽẽƌ ƌřìììṽẽẽẽřš, ăăăกี้ƌ ḩìììģḩƚìììģḩṯ ƥǚǚǚṯ-öööƒƒš ẽẽẽăăăřƚýýý. ]";
    'How we define importance': () =>
      | 'How we define importance'
      | 'คำอธิบายความสำคัญของปัจจัย'
      | 'Bagaimana kami mendefinisikan hal yang penting'
      | '[Ḩöööŵ ŵẽẽẽ ƌẽẽẽƒìììกี้ẽẽẽ ìììm̂ƥöööřṯăăăกี้çẽẽẽ]';
    'Candidates have ranked each driver according to 4 levels of importance:': () =>
      | 'Candidates have ranked each driver according to 4 levels of importance:'
      | 'ผู้สมัครได้จัดอันดับของแต่ละปัจจัยโดยเรียงตาม 4 ระดับความสำคัญ'
      | 'Kandidat telah diurutkan pada setiap faktor pendorong berdasarkan 4 tingkat kepentingan:'
      | '[Çăăăกี้ƌìììƌăăăṯẽẽẽš ḩăăăṽẽẽẽ řăăăกี้ķẽẽẽƌ ẽẽẽăăăçḩ ƌřìììṽẽẽẽř ăăăççöööřƌìììกี้ģ ṯööö 4 ƚẽẽẽṽẽẽẽƚš öööƒ ìììm̂ƥöööřṯăăăกี้çẽẽẽ:]';
    'Must-have: Essentials that candidates expect a job to have.': () =>
      | 'Must-have: Essentials that candidates expect a job to have.'
      | 'สิ่งที่ต้องมี: ปัจจัยสำคัญที่ผู้สมัครคาดหวังว่าจำเป็นต้องมี'
      | 'Harus dimiliki: Hal-hal penting yang diharapkan dimiliki kandidat dalam sebuah pekerjaan.'
      | '[Ṃǚǚǚšṯ-ḩăăăṽẽẽẽ: Ëššẽẽẽกี้ṯìììăăăƚš ṯḩăăăṯ çăăăกี้ƌìììƌăăăṯẽẽẽš ẽẽẽꭕƥẽẽẽçṯ ăăă ĵöööß ṯööö ḩăăăṽẽẽẽ.]';
    'Delighted-if: Extras that differentiate you from other companies.': () =>
      | 'Delighted-if: Extras that differentiate you from other companies.'
      | 'ดีถ้ามี: ปัจจัยที่ถ้าหากมีจะช่วยให้คุณแตกต่างจากบริษัทอื่น'
      | 'Bagus jika dimiliki: Hal-hal tambahan yang membedakan Anda dari perusahaan lain.'
      | '[Ḋẽẽẽƚìììģḩṯẽẽẽƌ-ìììƒ: Ëꭕṯřăăăš ṯḩăăăṯ ƌìììƒƒẽẽẽřẽẽẽกี้ṯìììăăăṯẽẽẽ ýýýöööǚǚǚ ƒřöööm̂ öööṯḩẽẽẽř çöööm̂ƥăăăกี้ìììẽẽẽš.]';
    "Not-bothered: Factors that don't really matter to candidates.": () =>
      | 'Not-bothered: Factors that don’t really matter to candidates.'
      | 'ไม่สนใจ: ปัจจัยที่ผู้สมัครไม่ได้ให้ความสนใจ'
      | 'Tidak perlu: Faktor-faktor yang tidak terlalu penting bagi kandidat.'
      | '[Ñöööṯ-ßöööṯḩẽẽẽřẽẽẽƌ: Ƒăăăçṯöööřš ṯḩăăăṯ ƌöööกี้’ṯ řẽẽẽăăăƚƚýýý m̂ăăăṯṯẽẽẽř ṯööö çăăăกี้ƌìììƌăăăṯẽẽẽš.]';
    "Put-off: Dealbreakers that candidates don't want in a role.": () =>
      | 'Put-off: Dealbreakers that candidates don’t want in a role.'
      | 'ปฏิเสธ: ปัจจัยที่มีผลทำให้ผู้สมัครปฏิเสธข้อเสนองาน'
      | 'Penolakan: Hal-hal yang tidak diinginkan oleh kandidat dalam sebuah posisi.'
      | '[Ƥǚǚǚṯ-öööƒƒ: Ḋẽẽẽăăăƚßřẽẽẽăăăķẽẽẽřš ṯḩăăăṯ çăăăกี้ƌìììƌăăăṯẽẽẽš ƌöööกี้’ṯ ŵăăăกี้ṯ ìììกี้ ăăă řöööƚẽẽẽ.]';
    'Must-have': () =>
      | 'Must-have'
      | 'สิ่งที่ต้องมี'
      | 'Harus dimiliki'
      | '[Ṃǚǚǚšṯ-ḩăăăṽẽẽẽ]';
    'Delighted-if': () =>
      | 'Delighted-if'
      | 'ดีถ้ามี'
      | 'Bagus jika dimiliki'
      | '[Ḋẽẽẽƚìììģḩṯẽẽẽƌ-ìììƒ]';
    'Not-bothered': () =>
      | 'Not-bothered'
      | 'ไม่สนใจ'
      | 'Tidak perlu'
      | '[Ñöööṯ-ßöööṯḩẽẽẽřẽẽẽƌ]';
    'Put-off': () => 'Put-off' | 'ปฏิเสธ' | 'Penolakan' | '[Ƥǚǚǚṯ-öööƒƒ]';
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.': (values: {
      filter: string;
    }) => string;
    'Refine data': () =>
      | 'Refine data'
      | 'คัดกรองข้อมูล'
      | 'Perbaiki data'
      | '[Řẽẽƒììกี้ẽẽ ƌăăṯăă]';
    'This sample size is statistically significant for the filters you have chosen.': () =>
      | 'This sample size is statistically significant for the filters you have chosen.'
      | 'ขนาดตัวอย่างนี้มีความสำคัญทางสถิติสำหรับตัวกรองที่คุณเลือก'
      | 'Ukuran sampel data secara statistik berpengaruh secara signifikan terhadap filter-filter yang Anda pilih'
      | '[Ṯḩìììš šăăăm̂ƥƚẽẽẽ šìììƶẽẽẽ ìììš šṯăăăṯìììšṯìììçăăăƚƚýýý šìììģกี้ìììƒìììçăăăกี้ṯ ƒöööř ṯḩẽẽẽ ƒìììƚṯẽẽẽřš ýýýöööǚǚǚ ḩăăăṽẽẽẽ çḩöööšẽẽẽกี้.]';
    'Download report': () =>
      | 'Download report'
      | 'ดาวน์โหลดรายงาน'
      | 'Unduh laporan'
      | '[Ḋöööŵกี้ƚöööăăăƌ řẽẽẽƥöööřṯ]';
    'January 2023 to February 2024': () =>
      | 'January 2023 to February 2024'
      | '[Ĵăăăกี้ǚǚǚăăăřýýý 2023 ṯööö Ƒẽẽẽßřǚǚǚăăăřýýý 2024]';
    'April 2024 to May 2024': () =>
      | 'April 2024 to May 2024'
      | 'เมษายน 2567 - พฤษภาคม 2567'
      | 'April 2024 hingga Mei 2024'
      | '[Ậƥřììììƚ 2024 ṯöööö Ṃăăăăýýýý 2024]';
    'Talent Attraction Advice': () =>
      | 'Talent Attraction Advice'
      | 'คำแนะนำในการดึงดูดผู้หางานที่ใช่'
      | 'Saran Talent Attraction'
      | '[Ṯăăăƚẽẽẽกี้ṯ Ậṯṯřăăăçṯìììöööกี้ Ậƌṽìììçẽẽẽ]';
    "SEEK's Talent Attraction Lab": () =>
      | 'SEEK Talent Attraction Lab'
      | 'Talent Attraction Lab'
      | '[ŠËËḰ Ṯăăăƚẽẽẽกี้ṯ Ậṯṯřăăăçṯìììöööกี้ Łăăăß]';
    'Uncover the drivers that attract candidates to a role.': () =>
      | 'Uncover the drivers that attract candidates to a role.'
      | 'พบกับข้อมูลเชิงลึก อะไรคือปัจจัยที่ดึงดูดใจผู้หางานในแต่ละตำแหน่ง'
      | 'Mengungkap faktor-faktor yang menarik kandidat melamar pekerjaan.'
      | '[Ǚกี้çöööṽẽẽẽř ṯḩẽẽẽ ƌřìììṽẽẽẽřš ṯḩăăăṯ ăăăṯṯřăăăçṯ çăăăกี้ƌìììƌăăăṯẽẽẽš ṯööö ăăă řöööƚẽẽẽ.]';
    'Top 3 drivers of attraction for': () =>
      | 'Top 3 drivers of attraction for'
      | 'ปัจจัยสำคัญ 3 อันดับแรกสำหรับ'
      | '3 faktor pendorong teratas menarik kandidat untuk'
      | '[Ṯöööƥ 3 ƌřìììṽẽẽẽřš öööƒ ăăăṯṯřăăăçṯìììöööกี้ ƒöööř]';
    "What's a must-have? An essential factor candidates expect a job to have.": () =>
      | "*What's a must-have? An essential factor candidates expect a job to have."
      | '*อะไรคือ สิ่งที่ต้องมี? นี่เป็นปัจจัยสำคัญที่ผู้สมัครคาดหวังว่าจำเป็นต้องมี'
      | '*Apa yang harus dimiliki? Faktor penting yang diharapkan kandidat ada dalam sebuah pekerjaan.'
      | "[*Ŵḩăăăṯ'š ăăă m̂ǚǚǚšṯ-ḩăăăṽẽẽẽ? Ậกี้ ẽẽẽššẽẽẽกี้ṯìììăăăƚ ƒăăăçṯöööř çăăăกี้ƌìììƌăăăṯẽẽẽš ẽẽẽꭕƥẽẽẽçṯ ăăă ĵöööß ṯööö ḩăăăṽẽẽẽ.]";
    "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:": () =>
      | "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:"
      | 'Talent Attraction Lab ออกแบบมาเพื่อช่วยให้คุณเข้าใจผู้หางานมากยิ่งขึ้น ว่าอะไรคือสิ่งที่พวกเขาต้องการเมื่อมองหางานหรือต้องพิจารณาว่าจะทำงานกับองค์กรต่อไป คุณสามารถนำปัจจัยเหล่านี้ไปใช้งานเพื่อดึงดูดผู้สมัครที่ใช่ได้ดังนี้'
      | 'Talent Attraction Lab didesain untuk membantu Anda untuk memahami lebih baik apa yang kandidat inginkan saat memutuskan untuk bergabung atau bertahan di suatu perusahaan. Berikut cara yang dapat Anda lakukan untuk memanfaatkan faktor-faktor untuk menarik perhatian kandidat.'
      | "[Ṯăăăƚẽẽẽกี้ṯ Ậṯṯřăăăçṯìììöööกี้ Łăăăß ìììš ƌẽẽẽšìììģกี้ẽẽẽƌ ṯööö ḩẽẽẽƚƥ ýýýöööǚǚǚ ßẽẽẽṯṯẽẽẽř ǚǚǚกี้ƌẽẽẽřšṯăăăกี้ƌ ŵḩăăăṯ çăăăกี้ƌìììƌăăăṯẽẽẽš řẽẽẽăăăƚƚýýý ŵăăăกี้ṯ ŵḩẽẽẽกี้ ƚööööööķìììกี้ģ ṯööö ĵöööìììกี้ öööř šṯăăăýýý ŵìììṯḩ ăăă ßǚǚǚšìììกี้ẽẽẽšš. Ḩẽẽẽřẽẽẽ'š ḩöööŵ ýýýöööǚǚǚ çăăăกี้ ǚǚǚšẽẽẽ ṯḩẽẽẽšẽẽẽ ƌřìììṽẽẽẽřš öööƒ ăăăṯṯřăăăçṯìììöööกี้ ṯööö ăăăƥƥẽẽẽăăăƚ ṯööö çăăăกี้ƌìììƌăăăṯẽẽẽš:]";
    'Mention these top three drivers of attraction in your job ad.': () =>
      | 'Mention these top three drivers of attraction in your job ad.'
      | 'กล่าวถึงปัจจัยสำคัญ 3 อันดับแรกในประกาศงานของคุณ'
      | 'Sebutkan 3 faktor pendorong utama untuk menarik kandidat dalam iklan lowongan kerja Anda.'
      | '[Ṃẽẽẽกี้ṯìììöööกี้ ṯḩẽẽẽšẽẽẽ ṯöööƥ ṯḩřẽẽẽẽẽẽ ƌřìììṽẽẽẽřš öööƒ ăăăṯṯřăăăçṯìììöööกี้ ìììกี้ ýýýöööǚǚǚř ĵöööß ăăăƌ.]';
    "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.": () =>
      | "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages."
      | 'ใช้ปัจจัยหลักเหล่านี้ในการสื่อสารกับผู้หางาน ไม่ว่าจะเป็นระหว่างการสัมภาษณ์หรือผ่านข้อความต่างๆ'
      | 'Gunakan faktor-faktor pendorong berikut untuk membantu Anda terhubung dengan kandidat dengan cara yang tepat, baik dalam wawancara atau berkirim pesan.'
      | "[Ǚšẽẽẽ ṯḩẽẽẽšẽẽẽ ƌřìììṽẽẽẽřš ṯööö ḩẽẽẽƚƥ ýýýöööǚǚǚ šƥẽẽẽăăăķ ṯööö çăăăกี้ƌìììƌăăăṯẽẽẽš ìììกี้ ŵăăăýýýš ṯḩăăăṯ řẽẽẽšöööกี้ăăăṯẽẽẽ ŵìììṯḩ ṯḩẽẽẽm̂, ŵḩẽẽẽṯḩẽẽẽř ìììṯ'š ìììกี้ ìììกี้ṯẽẽẽřṽìììẽẽẽŵš öööř m̂ẽẽẽššăăăģẽẽẽš.]";
    'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.': () =>
      | 'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.'
      | 'สื่อสารเกี่ยวกับปัจจัยสำคัญเหล่านี้เมื่อพูดถึงจุดเด่นหรือค่านิยมองค์กรของคุณ (EVP) เพื่อสร้างความโดดเด่นเหนือคู่แข่ง'
      | 'Sampaikan faktor-faktor pendorong tersebut dalam Employee Value Proposition (EVP) untuk membuat perusahaan Anda menonjol.'
      | '[Çöööm̂m̂ǚǚǚกี้ìììçăăăṯẽẽẽ ṯḩẽẽẽšẽẽẽ ƌřìììṽẽẽẽřš ìììกี้ ýýýöööǚǚǚř Ëm̂ƥƚöööýýýẽẽẽẽẽẽ Ṽăăăƚǚǚǚẽẽẽ Ƥřöööƥöööšìììṯìììöööกี้ (ËṼƤ) ṯööö m̂ăăăķẽẽẽ ýýýöööǚǚǚř öööřģăăăกี้ìììšăăăṯìììöööกี้ šṯăăăกี้ƌ öööǚǚǚṯ.]';
    'Visit www.seek.com.au/tal to discover a full list of detailed drivers.': <
      T = string,
    >(values: {
      Link: FormatXMLElementFn<T>;
    }) => ReturnType<FormatXMLElementFn<T>>;
    'About this research': () =>
      | 'About this research'
      | 'เกี่ยวกับการวิจัย'
      | 'Tentang penilitian ini'
      | '[Ậßöööǚǚǚṯ ṯḩìììš řẽẽẽšẽẽẽăăăřçḩ]';
    'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ': () =>
      | 'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. '
      | 'ข้อมูลที่อ้างถึงในหน้านี้มาจากการสำรวจ SEEK Talent Attraction Lab ในระหว่างเดือนเมษายน - พฤษภาคม 2567.'
      | 'Poin-poin pada data yang disebutkan dalam halaman ini diambil dari survei Talent Attraction Lab yang dilakukan pada April-Mei 2024.'
      | '[Ṯḩẽẽẽ ƌăăăṯăăă ƥöööìììกี้ṯš řẽẽẽƒẽẽẽřřẽẽẽƌ ṯööö öööกี้ ṯḩìììš ƥăăăģẽẽẽ ăăăřẽẽẽ ƌřăăăŵกี้ ƒřöööm̂ ṯḩẽẽẽ ŠËËḰ Ṯăăăƚẽẽẽกี้ṯ Ậṯṯřăăăçṯìììöööกี้ Łăăăß šǚǚǚřṽẽẽẽýýý ƒřöööm̂ Ậƥřìììƚ - Ṃăăăýýý 2024. ]';
    'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit {link}': <
      T = string,
    >(values: {
      Link: FormatXMLElementFn<T>;
    }) => ReturnType<FormatXMLElementFn<T>>;
    'Within this, secondary driver is the number one must-have for candidates.': (values: {
      driver: string;
    }) => string;
    'Data snapshot': () =>
      | 'Data snapshot'
      | 'สแนปชอตข้อมูล'
      | 'Cuplikan data'
      | '[Ḋăăăṯăăă šกี้ăăăƥšḩöööṯ]';
  }
>({
  en: createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Select filters to uncover deeper insights about candidates in {country}.',
    'Explore drivers by': 'Explore drivers by',
    'Select at least one option': 'Select at least one option',
    Share: 'Share',
    'Share on Facebook': 'Share on Facebook',
    'Share on X': 'Share on X',
    'Share on LinkedIn': 'Share on LinkedIn',
    'Copy Link': 'Copy Link',
    'Link copied to clipboard': 'Link copied to clipboard',
    'Clipboard access not available': 'Clipboard access not available',
    'Download PDF': 'Download PDF',
    'Showing results from': 'Showing results from',
    'All respondents': 'All respondents',
    'Showing results that match': 'Showing results that match',
    'Results are based on': 'Results are based on',
    'respondents from a total of': 'respondents from a total of',
    'candidates surveyed': 'candidates surveyed',
    'Drivers that attract these candidates':
      'Explore Key Talent Trends and Preferences',
    'About these candidates': 'Candidate Demographics and Behavior',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.",
    "We'll show this data when we have more responses from people who match your search.":
      "We'll show this data when we have more responses from people who match your search.",
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.",
    'In the meantime, you can adjust your filters to try a broader search.':
      'In the meantime, you can adjust your filters to try a broader search.',
    'These are the drivers candidates consider when looking at roles.':
      'These are the drivers candidates consider when looking at roles.',
    'Drivers are ranked with the most important driver at the top.':
      'Drivers are ranked with the most important driver at the top.',
    'Expand each driver for more detail.':
      'Expand each driver for more detail.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      "No data available yet for {filter}. We'll show this when we have enough responses.",
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Sample size for {filter} is small, so there is <u>greater margin of error.</u>',
    'When writing job ads':
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Candidates have ranked each driver according to 4 levels of importance:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Must-have: Essentials that candidates expect a job to have.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Delighted-if: Extras that differentiate you from other companies.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Not-bothered: Factors that don’t really matter to candidates.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Put-off: Dealbreakers that candidates don’t want in a role.',
    'Must-have': 'Must-have',
    'Delighted-if': 'Delighted-if',
    'Not-bothered': 'Not-bothered',
    'Put-off': 'Put-off',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'This is how candidates sort their preferences for the components of {filter}. Candidates have the option to choose more than one must-have.',
    'Refine data': 'Refine data',
    'This sample size is statistically significant for the filters you have chosen.':
      'This sample size is statistically significant for the filters you have chosen.',
    'Download report': 'Download report',
    'January 2023 to February 2024': 'January 2023 to February 2024',
    'April 2024 to May 2024': 'April 2024 to May 2024',
    'Talent Attraction Advice': 'Talent Attraction Advice',
    "SEEK's Talent Attraction Lab": 'SEEK Talent Attraction Lab',
    'Uncover the drivers that attract candidates to a role.':
      'Uncover the drivers that attract candidates to a role.',
    'Top 3 drivers of attraction for': 'Top 3 drivers of attraction for',
    "What's a must-have? An essential factor candidates expect a job to have.":
      "*What's a must-have? An essential factor candidates expect a job to have.",
    "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:":
      "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:",
    'Mention these top three drivers of attraction in your job ad.':
      'Mention these top three drivers of attraction in your job ad.',
    "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.":
      "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.",
    'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.':
      'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.',
    'Visit www.seek.com.au/tal to discover a full list of detailed drivers.':
      'Visit <Link>www.seek.com.au/tal</Link> to discover a full list of detailed drivers.',
    'About this research': 'About this research',
    'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ':
      'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ',
    'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit {link}':
      'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit <Link>link</Link>.',
    'Within this, secondary driver is the number one must-have for candidates.':
      'Within this, {driver} is the number one must-have* for candidates.',
    'Data snapshot': 'Data snapshot',
  }),
  th: createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'เลือกตัวกรองเพื่อเข้าถึงข้อมูลเชิงลึกเกี่ยวกับผู้สมัครใน ไทย',
    'Explore drivers by': 'ค้นหาปัจจัยตาม',
    'Select at least one option': 'เลือกอย่างน้อย 1 ตัวเลือก',
    Share: 'แชร์บน',
    'Share on Facebook': 'แชร์บน Facebook',
    'Share on X': 'แชร์บน X',
    'Share on LinkedIn': 'แชร์บน LinkedIn',
    'Copy Link': 'คัดลองลิงก์',
    'Link copied to clipboard': 'คัดลอกลิงก์',
    'Clipboard access not available': 'ไม่สามารถเข้าถึงคลิปบอร์ดได้',
    'Download PDF': 'ดาวน์โหลด PDF',
    'Showing results from': 'แสดงผลลัพธ์จาก',
    'All respondents': 'ผู้ตอบแบบสอบถามทุกคน',
    'Showing results that match': 'แสดงผลลัพธ์ที่ตรงความต้องการ',
    'Results are based on': 'ผลลัพธ์อาจขึ้นอยู่กับผู้ตอบแบบสอบถาม',
    'respondents from a total of': 'คน จากผู้หางานทั้งหมด',
    'candidates surveyed': 'คนที่เข้าร่วมตอบแบบสอบถามในช่วง',
    'Drivers that attract these candidates':
      'เข้าใจถึงแนวโน้มและปัจจัยสำคัญที่มีผลต่อผู้หางาน',
    'About these candidates': 'ข้อมูลประชากรและพฤติกรรมของผู้หางาน',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "ตัวเลขเปอร์เซ็นต์นี้แสดงถึงลำดับความสำคัญของแต่ละปัจจัยที่มีอิทธิพล ปัจจัยทั้งหมดอาจมีความ 'สำคัญ' แต่สิ่งนี้จะช่วยให้คุณเข้าใจการจัดลำดับความสำคัญของแต่ละปัจจัยจากผู้ตอบแบบสอบถามที่พวกเขามุ่งเน้นในประกาศงานและการสื่อสาร",
    "We'll show this data when we have more responses from people who match your search.":
      'เราจะแสดงข้อมูลนี้หากมีข้อมูลเพิ่มเติมจากผู้หางานที่ตรงความต้องการของคุณ',
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      'เรากำลังรวบรวมคำตอบจากผู้หางานเพื่อให้มั่นใจว่าอะไรคือสิ่งที่ดึงดูดใจผู้สมัครในการสมัครงานตำแหน่งนั้นๆ เราจะอัปเดตข้อมูลเมื่อเราดำเนินการเสร็จสิ้น',
    'In the meantime, you can adjust your filters to try a broader search.':
      'ในขณะเดียวกัน คุณสามารถปรับแต่งการคัดกรองเพื่อทำให้การค้นหากว้างขึ้น',
    'These are the drivers candidates consider when looking at roles.':
      'นี่คือปัจจัยที่ผู้สมัครงานพิจารณาเมื่อมองหางานใหม่',
    'Drivers are ranked with the most important driver at the top.':
      'ปัจจัยเหล่านี้จะจัดเรียงตามลำดับความสำคัญต่อผู้หางานโดยปัจจัยที่มีอิทธิพลต่อผู้หางานมากที่สุดจะอยู่ด้านบน',
    'Expand each driver for more detail.':
      'คุณสามารถคลิกแสดงเนื้อหาเพื่อดูรายละเอียดเพิ่มเติม',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      'ยังไม่มีข้อมูลสำหรับ {filter}  เราจะแสดงข้อมูลนี้อีกครั้งหากมีรายละเอียดเพิ่มเติม',
    'Sample size for selected filters is small, so there is greater margin of error.':
      'ขนาดตัวอย่างสำหรับ {filter} มีจำนวนน้อยเกินไป <u>ดังนั้นข้อมูลอาจมีค่าความคลาดเคลื่อนสูง</u>',
    'When writing job ads':
      'เมื่อเขียนประกาศงาน ให้มุ่งเน้นไปที่ปัจจัยที่จำเป็นต้องมีและปัจจัยที่ดีถ้ามี เพื่อสร้างความโดดเด่นเหนือคู่แข่ง อย่าเสียเวลาไปกับปัจจัยที่ผู้หางานไม่ได้ให้ความสนใจและปัจจัยที่อาจนำไปสู่การปฏิเสธงาน',
    'How we define importance': 'คำอธิบายความสำคัญของปัจจัย',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'ผู้สมัครได้จัดอันดับของแต่ละปัจจัยโดยเรียงตาม 4 ระดับความสำคัญ',
    'Must-have: Essentials that candidates expect a job to have.':
      'สิ่งที่ต้องมี: ปัจจัยสำคัญที่ผู้สมัครคาดหวังว่าจำเป็นต้องมี',
    'Delighted-if: Extras that differentiate you from other companies.':
      'ดีถ้ามี: ปัจจัยที่ถ้าหากมีจะช่วยให้คุณแตกต่างจากบริษัทอื่น',
    "Not-bothered: Factors that don't really matter to candidates.":
      'ไม่สนใจ: ปัจจัยที่ผู้สมัครไม่ได้ให้ความสนใจ',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'ปฏิเสธ: ปัจจัยที่มีผลทำให้ผู้สมัครปฏิเสธข้อเสนองาน',
    'Must-have': 'สิ่งที่ต้องมี',
    'Delighted-if': 'ดีถ้ามี',
    'Not-bothered': 'ไม่สนใจ',
    'Put-off': 'ปฏิเสธ',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'นี่คือวิธีที่ผู้สมัครใช้เพื่อจัดอันดับความต้องการของตนเองสำหรับปัจจัยเกี่ยวกับ {filter} ผู้สมัครสามารถเลือกสิ่งที่ต้องมีได้มากกว่าหนึ่งตัวเลือก',
    'Refine data': 'คัดกรองข้อมูล',
    'This sample size is statistically significant for the filters you have chosen.':
      'ขนาดตัวอย่างนี้มีความสำคัญทางสถิติสำหรับตัวกรองที่คุณเลือก',
    'Download report': 'ดาวน์โหลดรายงาน',
    'January 2023 to February 2024': 'January 2023 to February 2024',
    'April 2024 to May 2024': 'เมษายน 2567 - พฤษภาคม 2567',
    'Talent Attraction Advice': 'คำแนะนำในการดึงดูดผู้หางานที่ใช่',
    "SEEK's Talent Attraction Lab": 'SEEK Talent Attraction Lab',
    'Uncover the drivers that attract candidates to a role.':
      'พบกับข้อมูลเชิงลึก อะไรคือปัจจัยที่ดึงดูดใจผู้หางานในแต่ละตำแหน่ง',
    'Top 3 drivers of attraction for': 'ปัจจัยสำคัญ 3 อันดับแรกสำหรับ',
    "What's a must-have? An essential factor candidates expect a job to have.":
      '*อะไรคือ สิ่งที่ต้องมี? นี่เป็นปัจจัยสำคัญที่ผู้สมัครคาดหวังว่าจำเป็นต้องมี',
    "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:":
      'Talent Attraction Lab ออกแบบมาเพื่อช่วยให้คุณเข้าใจผู้หางานมากยิ่งขึ้น ว่าอะไรคือสิ่งที่พวกเขาต้องการเมื่อมองหางานหรือต้องพิจารณาว่าจะทำงานกับองค์กรต่อไป คุณสามารถนำปัจจัยเหล่านี้ไปใช้งานเพื่อดึงดูดผู้สมัครที่ใช่ได้ดังนี้',
    'Mention these top three drivers of attraction in your job ad.':
      'กล่าวถึงปัจจัยสำคัญ 3 อันดับแรกในประกาศงานของคุณ',
    "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.":
      'ใช้ปัจจัยหลักเหล่านี้ในการสื่อสารกับผู้หางาน ไม่ว่าจะเป็นระหว่างการสัมภาษณ์หรือผ่านข้อความต่างๆ',
    'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.':
      'สื่อสารเกี่ยวกับปัจจัยสำคัญเหล่านี้เมื่อพูดถึงจุดเด่นหรือค่านิยมองค์กรของคุณ (EVP) เพื่อสร้างความโดดเด่นเหนือคู่แข่ง',
    'Visit www.seek.com.au/tal to discover a full list of detailed drivers.':
      'ค้นหาเพิ่มเติมเกี่ยวกับปัจจัยสำคัญเหล่านี้ได้ที่ <Link>www.seek.com.au/tal</Link>',
    'About this research': 'เกี่ยวกับการวิจัย',
    'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ':
      'ข้อมูลที่อ้างถึงในหน้านี้มาจากการสำรวจ SEEK Talent Attraction Lab ในระหว่างเดือนเมษายน - พฤษภาคม 2567.',
    'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit {link}':
      'ต้องการข้อมูลเพิ่มเติมเกี่ยวกับการสำรวจ SEEK Talent Attraction Lab รวมถึงข้อกำหนดและเงื่อนไขเกี่ยวกับการใช้งานข้อมูลนี้ สามารถดูเพิ่มเติมได้ที่ <Link>link</Link>.',
    'Within this, secondary driver is the number one must-have for candidates.':
      'ในปัจจัยนี้ {driver} คือสิ่งที่ต้องมี* สำหรับผู้หางาน.',
    'Data snapshot': 'สแนปชอตข้อมูล',
  }),
  id: createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Pilih filter untuk mendapatkan wawasan lebih dalam tentang kandidat Indonesia',
    'Explore drivers by': 'Cari tahu faktor berdasarkan',
    'Select at least one option': 'Pilih setidaknya satu opsi',
    Share: 'Bagikan',
    'Share on Facebook': 'Bagikan di Facebook',
    'Share on X': 'Bagikan di X',
    'Share on LinkedIn': 'Bagikan di LinkedIn',
    'Copy Link': 'Salin tautan',
    'Link copied to clipboard': 'Tautan disalin ke papan clip',
    'Clipboard access not available': 'Akses ke papan klik tidak tersedia',
    'Download PDF': 'Unduh PDF',
    'Showing results from': 'Menampilkan Hasil dari',
    'All respondents': 'Semua responden',
    'Showing results that match': 'Menampilkan hasil yang cocok',
    'Results are based on': 'Hasil berdasarkan',
    'respondents from a total of': 'responden dari total',
    'candidates surveyed': 'kandidat yang disurvei dalam',
    'Drivers that attract these candidates':
      'Pelajari Kunci Tentang Tren Kandidat Terkini dan Preferensinya',
    'About these candidates': 'Demografi dan Tipe Kandidat',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "Persentase ini adalah cara kandidat memberi peringkat faktor pendorong berdasarkan tingkat kepentingan. Semua faktor pendorong bisa jadi 'penting', tetapi ini membantu Anda memprioritaskan faktor mana untuk difokuskan dalam iklan pekerjaan atau percakapan.",
    "We'll show this data when we have more responses from people who match your search.":
      'Kami akan menampilkan data ini ketika kami memiliki jumlah respon yang cukup dari orang yang sesuai dengan pencarian Anda.',
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      'Kami berusaha mengumpulkan respon yang cukup dari para kandidat ini untuk berbagi apa yang membuat mereka tertarik pada suatu posisi dengan percaya diri. Kami akan memperbarui data ketika kami berhasil mengumpulkannya.',
    'In the meantime, you can adjust your filters to try a broader search.':
      'Sementara itu, Anda bisa menyesuaikan filter Anda untuk mencoba pencarian yang lebih luas.',
    'These are the drivers candidates consider when looking at roles.':
      'Ini adalah faktor pendorong yang dipertimbangkan kandidat saat melihat posisi.',
    'Drivers are ranked with the most important driver at the top.':
      'Faktor pendorong diurutkan dari yang terpenting di bagian atas.',
    'Expand each driver for more detail.':
      'Perluas setiap faktor untuk detail lebih lanjut.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      'Belum ada data yang tersedia untuk {filter}. Kami akan menunjukkan ini saat kami memiliki cukup respons.',
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Ukuran sampel untuk {filter} kecil, jadi ada <u>margin kesalahan yang lebih besar.</u>',
    'When writing job ads':
      'Saat menulis iklan lowongan kerja, fokuslah pada faktor-faktor pendorong utama yang menarik kandidat dan yang membedakan Anda dengan kompetitor. Jangan fokus pada faktor pendorong yang kurang menarik. ',
    'How we define importance':
      'Bagaimana kami mendefinisikan hal yang penting',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Kandidat telah diurutkan pada setiap faktor pendorong berdasarkan 4 tingkat kepentingan:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Harus dimiliki: Hal-hal penting yang diharapkan dimiliki kandidat dalam sebuah pekerjaan.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Bagus jika dimiliki: Hal-hal tambahan yang membedakan Anda dari perusahaan lain.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Tidak perlu: Faktor-faktor yang tidak terlalu penting bagi kandidat.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Penolakan: Hal-hal yang tidak diinginkan oleh kandidat dalam sebuah posisi.',
    'Must-have': 'Harus dimiliki',
    'Delighted-if': 'Bagus jika dimiliki',
    'Not-bothered': 'Tidak perlu',
    'Put-off': 'Penolakan',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'Ini adalah cara kandidat mengurutkan preferensi mereka untuk komponen {filter}. Kandidat memiliki opsi untuk memilih lebih dari satu yang harus dimiliki.',
    'Refine data': 'Perbaiki data',
    'This sample size is statistically significant for the filters you have chosen.':
      'Ukuran sampel data secara statistik berpengaruh secara signifikan terhadap filter-filter yang Anda pilih',
    'Download report': 'Unduh laporan',
    'January 2023 to February 2024': 'January 2023 to February 2024',
    'April 2024 to May 2024': 'April 2024 hingga Mei 2024',
    'Talent Attraction Advice': 'Saran Talent Attraction',
    "SEEK's Talent Attraction Lab": 'Talent Attraction Lab',
    'Uncover the drivers that attract candidates to a role.':
      'Mengungkap faktor-faktor yang menarik kandidat melamar pekerjaan.',
    'Top 3 drivers of attraction for':
      '3 faktor pendorong teratas menarik kandidat untuk',
    "What's a must-have? An essential factor candidates expect a job to have.":
      '*Apa yang harus dimiliki? Faktor penting yang diharapkan kandidat ada dalam sebuah pekerjaan.',
    "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:":
      'Talent Attraction Lab didesain untuk membantu Anda untuk memahami lebih baik apa yang kandidat inginkan saat memutuskan untuk bergabung atau bertahan di suatu perusahaan. Berikut cara yang dapat Anda lakukan untuk memanfaatkan faktor-faktor untuk menarik perhatian kandidat.',
    'Mention these top three drivers of attraction in your job ad.':
      'Sebutkan 3 faktor pendorong utama untuk menarik kandidat dalam iklan lowongan kerja Anda.',
    "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.":
      'Gunakan faktor-faktor pendorong berikut untuk membantu Anda terhubung dengan kandidat dengan cara yang tepat, baik dalam wawancara atau berkirim pesan.',
    'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.':
      'Sampaikan faktor-faktor pendorong tersebut dalam Employee Value Proposition (EVP) untuk membuat perusahaan Anda menonjol.',
    'Visit www.seek.com.au/tal to discover a full list of detailed drivers.':
      'Kunjungi <Link>www.seek.com.au/tal</Link> untuk melihat daftar lengkap faktor-faktor pendorong dengan terperinci.',
    'About this research': 'Tentang penilitian ini',
    'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ':
      'Poin-poin pada data yang disebutkan dalam halaman ini diambil dari survei Talent Attraction Lab yang dilakukan pada April-Mei 2024.',
    'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit {link}':
      'Untuk dapatkan informasi lebih lanjut tentang survei Talent Attraction Lab SEEK dan ketentuan serta kondisi yang memengaruhi penggunaan data tersebut, kunjungi <Link>www.seek.com.au/tal</Link>.',
    'Within this, secondary driver is the number one must-have for candidates.':
      'Dengan demikian, {driver} adalah hal yang paling utama* bagi kandidat.',
    'Data snapshot': 'Cuplikan data',
  }),
  zh: createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Select filters to uncover deeper insights about candidates in {country}.',
    'Explore drivers by': 'Explore drivers by',
    'Select at least one option': 'Select at least one option',
    Share: 'Share',
    'Share on Facebook': 'Share on Facebook',
    'Share on X': 'Share on X',
    'Share on LinkedIn': 'Share on LinkedIn',
    'Copy Link': 'Copy Link',
    'Link copied to clipboard': 'Link copied to clipboard',
    'Clipboard access not available': 'Clipboard access not available',
    'Download PDF': 'Download PDF',
    'Showing results from': 'Showing results from',
    'All respondents': 'All respondents',
    'Showing results that match': 'Showing results that match',
    'Results are based on': 'Results are based on',
    'respondents from a total of': 'respondents from a total of',
    'candidates surveyed': 'candidates surveyed',
    'Drivers that attract these candidates':
      'Explore Key Talent Trends and Preferences',
    'About these candidates': 'Candidate Demographics and Behavior',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.",
    "We'll show this data when we have more responses from people who match your search.":
      "We'll show this data when we have more responses from people who match your search.",
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.",
    'In the meantime, you can adjust your filters to try a broader search.':
      'In the meantime, you can adjust your filters to try a broader search.',
    'These are the drivers candidates consider when looking at roles.':
      'These are the drivers candidates consider when looking at roles.',
    'Drivers are ranked with the most important driver at the top.':
      'Drivers are ranked with the most important driver at the top.',
    'Expand each driver for more detail.':
      'Expand each driver for more detail.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      "No data available yet for {filter}. We'll show this when we have enough responses.",
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Sample size for {filter} is small, so there is <u>greater margin of error.</u>',
    'When writing job ads':
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Candidates have ranked each driver according to 4 levels of importance:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Must-have: Essentials that candidates expect a job to have.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Delighted-if: Extras that differentiate you from other companies.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Not-bothered: Factors that don’t really matter to candidates.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Put-off: Dealbreakers that candidates don’t want in a role.',
    'Must-have': 'Must-have',
    'Delighted-if': 'Delighted-if',
    'Not-bothered': 'Not-bothered',
    'Put-off': 'Put-off',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'This is how candidates sort their preferences for the components of {filter}. Candidates have the option to choose more than one must-have.',
    'Refine data': 'Refine data',
    'This sample size is statistically significant for the filters you have chosen.':
      'This sample size is statistically significant for the filters you have chosen.',
    'Download report': 'Download report',
    'January 2023 to February 2024': 'January 2023 to February 2024',
    'April 2024 to May 2024': 'April 2024 to May 2024',
    'Talent Attraction Advice': 'Talent Attraction Advice',
    "SEEK's Talent Attraction Lab": 'SEEK Talent Attraction Lab',
    'Uncover the drivers that attract candidates to a role.':
      'Uncover the drivers that attract candidates to a role.',
    'Top 3 drivers of attraction for': 'Top 3 drivers of attraction for',
    "What's a must-have? An essential factor candidates expect a job to have.":
      "*What's a must-have? An essential factor candidates expect a job to have.",
    "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:":
      "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:",
    'Mention these top three drivers of attraction in your job ad.':
      'Mention these top three drivers of attraction in your job ad.',
    "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.":
      "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.",
    'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.':
      'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.',
    'Visit www.seek.com.au/tal to discover a full list of detailed drivers.':
      'Visit <Link>www.seek.com.au/tal</Link> to discover a full list of detailed drivers.',
    'About this research': 'About this research',
    'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ':
      'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ',
    'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit {link}':
      'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit <Link>link</Link>.',
    'Within this, secondary driver is the number one must-have for candidates.':
      'Within this, {driver} is the number one must-have* for candidates.',
    'Data snapshot': 'Data snapshot',
  }),
  'en-AU': createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Select filters to uncover deeper insights about candidates in {country}.',
    'Explore drivers by': 'Explore drivers by',
    'Select at least one option': 'Select at least one option',
    Share: 'Share',
    'Share on Facebook': 'Share on Facebook',
    'Share on X': 'Share on X',
    'Share on LinkedIn': 'Share on LinkedIn',
    'Copy Link': 'Copy Link',
    'Link copied to clipboard': 'Link copied to clipboard',
    'Clipboard access not available': 'Clipboard access not available',
    'Download PDF': 'Download PDF',
    'Showing results from': 'Showing results from',
    'All respondents': 'All respondents',
    'Showing results that match': 'Showing results that match',
    'Results are based on': 'Results are based on',
    'respondents from a total of': 'respondents from a total of',
    'candidates surveyed': 'candidates surveyed',
    'Drivers that attract these candidates':
      'Explore Key Talent Trends and Preferences',
    'About these candidates': 'Candidate Demographics and Behavior',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.",
    "We'll show this data when we have more responses from people who match your search.":
      "We'll show this data when we have more responses from people who match your search.",
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.",
    'In the meantime, you can adjust your filters to try a broader search.':
      'In the meantime, you can adjust your filters to try a broader search.',
    'These are the drivers candidates consider when looking at roles.':
      'These are the drivers candidates consider when looking at roles.',
    'Drivers are ranked with the most important driver at the top.':
      'Drivers are ranked with the most important driver at the top.',
    'Expand each driver for more detail.':
      'Expand each driver for more detail.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      "No data available yet for {filter}. We'll show this when we have enough responses.",
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Sample size for {filter} is small, so there is <u>greater margin of error.</u>',
    'When writing job ads':
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Candidates have ranked each driver according to 4 levels of importance:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Must-have: Essentials that candidates expect a job to have.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Delighted-if: Extras that differentiate you from other companies.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Not-bothered: Factors that don’t really matter to candidates.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Put-off: Dealbreakers that candidates don’t want in a role.',
    'Must-have': 'Must-have',
    'Delighted-if': 'Delighted-if',
    'Not-bothered': 'Not-bothered',
    'Put-off': 'Put-off',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'This is how candidates sort their preferences for the components of {filter}. Candidates have the option to choose more than one must-have.',
    'Refine data': 'Refine data',
    'This sample size is statistically significant for the filters you have chosen.':
      'This sample size is statistically significant for the filters you have chosen.',
    'Download report': 'Download report',
    'January 2023 to February 2024': 'January 2023 to February 2024',
    'April 2024 to May 2024': 'April 2024 to May 2024',
    'Talent Attraction Advice': 'Talent Attraction Advice',
    "SEEK's Talent Attraction Lab": 'SEEK Talent Attraction Lab',
    'Uncover the drivers that attract candidates to a role.':
      'Uncover the drivers that attract candidates to a role.',
    'Top 3 drivers of attraction for': 'Top 3 drivers of attraction for',
    "What's a must-have? An essential factor candidates expect a job to have.":
      "*What's a must-have? An essential factor candidates expect a job to have.",
    "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:":
      "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:",
    'Mention these top three drivers of attraction in your job ad.':
      'Mention these top three drivers of attraction in your job ad.',
    "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.":
      "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.",
    'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.':
      'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.',
    'Visit www.seek.com.au/tal to discover a full list of detailed drivers.':
      'Visit <Link>www.seek.com.au/tal</Link> to discover a full list of detailed drivers.',
    'About this research': 'About this research',
    'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ':
      'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ',
    'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit {link}':
      'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit <Link>link</Link>.',
    'Within this, secondary driver is the number one must-have for candidates.':
      'Within this, {driver} is the number one must-have* for candidates.',
    'Data snapshot': 'Data snapshot',
  }),
  'en-HK': createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Select filters to uncover deeper insights about candidates in {country}.',
    'Explore drivers by': 'Explore drivers by',
    'Select at least one option': 'Select at least one option',
    Share: 'Share',
    'Share on Facebook': 'Share on Facebook',
    'Share on X': 'Share on X',
    'Share on LinkedIn': 'Share on LinkedIn',
    'Copy Link': 'Copy Link',
    'Link copied to clipboard': 'Link copied to clipboard',
    'Clipboard access not available': 'Clipboard access not available',
    'Download PDF': 'Download PDF',
    'Showing results from': 'Showing results from',
    'All respondents': 'All respondents',
    'Showing results that match': 'Showing results that match',
    'Results are based on': 'Results are based on',
    'respondents from a total of': 'respondents from a total of',
    'candidates surveyed': 'candidates surveyed',
    'Drivers that attract these candidates':
      'Explore Key Talent Trends and Preferences',
    'About these candidates': 'Candidate Demographics and Behavior',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.",
    "We'll show this data when we have more responses from people who match your search.":
      "We'll show this data when we have more responses from people who match your search.",
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.",
    'In the meantime, you can adjust your filters to try a broader search.':
      'In the meantime, you can adjust your filters to try a broader search.',
    'These are the drivers candidates consider when looking at roles.':
      'These are the drivers candidates consider when looking at roles.',
    'Drivers are ranked with the most important driver at the top.':
      'Drivers are ranked with the most important driver at the top.',
    'Expand each driver for more detail.':
      'Expand each driver for more detail.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      "No data available yet for {filter}. We'll show this when we have enough responses.",
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Sample size for {filter} is small, so there is <u>greater margin of error.</u>',
    'When writing job ads':
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Candidates have ranked each driver according to 4 levels of importance:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Must-have: Essentials that candidates expect a job to have.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Delighted-if: Extras that differentiate you from other companies.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Not-bothered: Factors that don’t really matter to candidates.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Put-off: Dealbreakers that candidates don’t want in a role.',
    'Must-have': 'Must-have',
    'Delighted-if': 'Delighted-if',
    'Not-bothered': 'Not-bothered',
    'Put-off': 'Put-off',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'This is how candidates sort their preferences for the components of {filter}. Candidates have the option to choose more than one must-have.',
    'Refine data': 'Refine data',
    'This sample size is statistically significant for the filters you have chosen.':
      'This sample size is statistically significant for the filters you have chosen.',
    'Download report': 'Download report',
    'January 2023 to February 2024': 'January 2023 to February 2024',
    'April 2024 to May 2024': 'April 2024 to May 2024',
    'Talent Attraction Advice': 'Talent Attraction Advice',
    "SEEK's Talent Attraction Lab": 'SEEK Talent Attraction Lab',
    'Uncover the drivers that attract candidates to a role.':
      'Uncover the drivers that attract candidates to a role.',
    'Top 3 drivers of attraction for': 'Top 3 drivers of attraction for',
    "What's a must-have? An essential factor candidates expect a job to have.":
      "*What's a must-have? An essential factor candidates expect a job to have.",
    "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:":
      "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:",
    'Mention these top three drivers of attraction in your job ad.':
      'Mention these top three drivers of attraction in your job ad.',
    "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.":
      "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.",
    'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.':
      'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.',
    'Visit www.seek.com.au/tal to discover a full list of detailed drivers.':
      'Visit <Link>www.seek.com.au/tal</Link> to discover a full list of detailed drivers.',
    'About this research': 'About this research',
    'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ':
      'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ',
    'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit {link}':
      'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit <Link>link</Link>.',
    'Within this, secondary driver is the number one must-have for candidates.':
      'Within this, {driver} is the number one must-have* for candidates.',
    'Data snapshot': 'Data snapshot',
  }),
  'en-ID': createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Select filters to uncover deeper insights about candidates in {country}.',
    'Explore drivers by': 'Explore drivers by',
    'Select at least one option': 'Select at least one option',
    Share: 'Share',
    'Share on Facebook': 'Share on Facebook',
    'Share on X': 'Share on X',
    'Share on LinkedIn': 'Share on LinkedIn',
    'Copy Link': 'Copy Link',
    'Link copied to clipboard': 'Link copied to clipboard',
    'Clipboard access not available': 'Clipboard access not available',
    'Download PDF': 'Download PDF',
    'Showing results from': 'Showing results from',
    'All respondents': 'All respondents',
    'Showing results that match': 'Showing results that match',
    'Results are based on': 'Results are based on',
    'respondents from a total of': 'respondents from a total of',
    'candidates surveyed': 'candidates surveyed',
    'Drivers that attract these candidates':
      'Explore Key Talent Trends and Preferences',
    'About these candidates': 'Candidate Demographics and Behavior',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.",
    "We'll show this data when we have more responses from people who match your search.":
      "We'll show this data when we have more responses from people who match your search.",
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.",
    'In the meantime, you can adjust your filters to try a broader search.':
      'In the meantime, you can adjust your filters to try a broader search.',
    'These are the drivers candidates consider when looking at roles.':
      'These are the drivers candidates consider when looking at roles.',
    'Drivers are ranked with the most important driver at the top.':
      'Drivers are ranked with the most important driver at the top.',
    'Expand each driver for more detail.':
      'Expand each driver for more detail.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      "No data available yet for {filter}. We'll show this when we have enough responses.",
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Sample size for {filter} is small, so there is <u>greater margin of error.</u>',
    'When writing job ads':
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Candidates have ranked each driver according to 4 levels of importance:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Must-have: Essentials that candidates expect a job to have.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Delighted-if: Extras that differentiate you from other companies.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Not-bothered: Factors that don’t really matter to candidates.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Put-off: Dealbreakers that candidates don’t want in a role.',
    'Must-have': 'Must-have',
    'Delighted-if': 'Delighted-if',
    'Not-bothered': 'Not-bothered',
    'Put-off': 'Put-off',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'This is how candidates sort their preferences for the components of {filter}. Candidates have the option to choose more than one must-have.',
    'Refine data': 'Refine data',
    'This sample size is statistically significant for the filters you have chosen.':
      'This sample size is statistically significant for the filters you have chosen.',
    'Download report': 'Download report',
    'January 2023 to February 2024': 'January 2023 to February 2024',
    'April 2024 to May 2024': 'April 2024 to May 2024',
    'Talent Attraction Advice': 'Talent Attraction Advice',
    "SEEK's Talent Attraction Lab": 'SEEK Talent Attraction Lab',
    'Uncover the drivers that attract candidates to a role.':
      'Uncover the drivers that attract candidates to a role.',
    'Top 3 drivers of attraction for': 'Top 3 drivers of attraction for',
    "What's a must-have? An essential factor candidates expect a job to have.":
      "*What's a must-have? An essential factor candidates expect a job to have.",
    "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:":
      "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:",
    'Mention these top three drivers of attraction in your job ad.':
      'Mention these top three drivers of attraction in your job ad.',
    "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.":
      "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.",
    'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.':
      'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.',
    'Visit www.seek.com.au/tal to discover a full list of detailed drivers.':
      'Visit <Link>www.seek.com.au/tal</Link> to discover a full list of detailed drivers.',
    'About this research': 'About this research',
    'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ':
      'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ',
    'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit {link}':
      'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit <Link>link</Link>.',
    'Within this, secondary driver is the number one must-have for candidates.':
      'Within this, {driver} is the number one must-have* for candidates.',
    'Data snapshot': 'Data snapshot',
  }),
  'en-MY': createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Select filters to uncover deeper insights about candidates in {country}.',
    'Explore drivers by': 'Explore drivers by',
    'Select at least one option': 'Select at least one option',
    Share: 'Share',
    'Share on Facebook': 'Share on Facebook',
    'Share on X': 'Share on X',
    'Share on LinkedIn': 'Share on LinkedIn',
    'Copy Link': 'Copy Link',
    'Link copied to clipboard': 'Link copied to clipboard',
    'Clipboard access not available': 'Clipboard access not available',
    'Download PDF': 'Download PDF',
    'Showing results from': 'Showing results from',
    'All respondents': 'All respondents',
    'Showing results that match': 'Showing results that match',
    'Results are based on': 'Results are based on',
    'respondents from a total of': 'respondents from a total of',
    'candidates surveyed': 'candidates surveyed',
    'Drivers that attract these candidates':
      'Explore Key Talent Trends and Preferences',
    'About these candidates': 'Candidate Demographics and Behavior',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.",
    "We'll show this data when we have more responses from people who match your search.":
      "We'll show this data when we have more responses from people who match your search.",
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.",
    'In the meantime, you can adjust your filters to try a broader search.':
      'In the meantime, you can adjust your filters to try a broader search.',
    'These are the drivers candidates consider when looking at roles.':
      'These are the drivers candidates consider when looking at roles.',
    'Drivers are ranked with the most important driver at the top.':
      'Drivers are ranked with the most important driver at the top.',
    'Expand each driver for more detail.':
      'Expand each driver for more detail.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      "No data available yet for {filter}. We'll show this when we have enough responses.",
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Sample size for {filter} is small, so there is <u>greater margin of error.</u>',
    'When writing job ads':
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Candidates have ranked each driver according to 4 levels of importance:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Must-have: Essentials that candidates expect a job to have.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Delighted-if: Extras that differentiate you from other companies.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Not-bothered: Factors that don’t really matter to candidates.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Put-off: Dealbreakers that candidates don’t want in a role.',
    'Must-have': 'Must-have',
    'Delighted-if': 'Delighted-if',
    'Not-bothered': 'Not-bothered',
    'Put-off': 'Put-off',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'This is how candidates sort their preferences for the components of {filter}. Candidates have the option to choose more than one must-have.',
    'Refine data': 'Refine data',
    'This sample size is statistically significant for the filters you have chosen.':
      'This sample size is statistically significant for the filters you have chosen.',
    'Download report': 'Download report',
    'January 2023 to February 2024': 'January 2023 to February 2024',
    'April 2024 to May 2024': 'April 2024 to May 2024',
    'Talent Attraction Advice': 'Talent Attraction Advice',
    "SEEK's Talent Attraction Lab": 'SEEK Talent Attraction Lab',
    'Uncover the drivers that attract candidates to a role.':
      'Uncover the drivers that attract candidates to a role.',
    'Top 3 drivers of attraction for': 'Top 3 drivers of attraction for',
    "What's a must-have? An essential factor candidates expect a job to have.":
      "*What's a must-have? An essential factor candidates expect a job to have.",
    "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:":
      "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:",
    'Mention these top three drivers of attraction in your job ad.':
      'Mention these top three drivers of attraction in your job ad.',
    "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.":
      "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.",
    'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.':
      'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.',
    'Visit www.seek.com.au/tal to discover a full list of detailed drivers.':
      'Visit <Link>www.seek.com.au/tal</Link> to discover a full list of detailed drivers.',
    'About this research': 'About this research',
    'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ':
      'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ',
    'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit {link}':
      'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit <Link>link</Link>.',
    'Within this, secondary driver is the number one must-have for candidates.':
      'Within this, {driver} is the number one must-have* for candidates.',
    'Data snapshot': 'Data snapshot',
  }),
  'en-NZ': createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Select filters to uncover deeper insights about candidates in {country}.',
    'Explore drivers by': 'Explore drivers by',
    'Select at least one option': 'Select at least one option',
    Share: 'Share',
    'Share on Facebook': 'Share on Facebook',
    'Share on X': 'Share on X',
    'Share on LinkedIn': 'Share on LinkedIn',
    'Copy Link': 'Copy Link',
    'Link copied to clipboard': 'Link copied to clipboard',
    'Clipboard access not available': 'Clipboard access not available',
    'Download PDF': 'Download PDF',
    'Showing results from': 'Showing results from',
    'All respondents': 'All respondents',
    'Showing results that match': 'Showing results that match',
    'Results are based on': 'Results are based on',
    'respondents from a total of': 'respondents from a total of',
    'candidates surveyed': 'candidates surveyed',
    'Drivers that attract these candidates':
      'Explore Key Talent Trends and Preferences',
    'About these candidates': 'Candidate Demographics and Behavior',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.",
    "We'll show this data when we have more responses from people who match your search.":
      "We'll show this data when we have more responses from people who match your search.",
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.",
    'In the meantime, you can adjust your filters to try a broader search.':
      'In the meantime, you can adjust your filters to try a broader search.',
    'These are the drivers candidates consider when looking at roles.':
      'These are the drivers candidates consider when looking at roles.',
    'Drivers are ranked with the most important driver at the top.':
      'Drivers are ranked with the most important driver at the top.',
    'Expand each driver for more detail.':
      'Expand each driver for more detail.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      "No data available yet for {filter}. We'll show this when we have enough responses.",
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Sample size for {filter} is small, so there is <u>greater margin of error.</u>',
    'When writing job ads':
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Candidates have ranked each driver according to 4 levels of importance:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Must-have: Essentials that candidates expect a job to have.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Delighted-if: Extras that differentiate you from other companies.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Not-bothered: Factors that don’t really matter to candidates.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Put-off: Dealbreakers that candidates don’t want in a role.',
    'Must-have': 'Must-have',
    'Delighted-if': 'Delighted-if',
    'Not-bothered': 'Not-bothered',
    'Put-off': 'Put-off',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'This is how candidates sort their preferences for the components of {filter}. Candidates have the option to choose more than one must-have.',
    'Refine data': 'Refine data',
    'This sample size is statistically significant for the filters you have chosen.':
      'This sample size is statistically significant for the filters you have chosen.',
    'Download report': 'Download report',
    'January 2023 to February 2024': 'January 2023 to February 2024',
    'April 2024 to May 2024': 'April 2024 to May 2024',
    'Talent Attraction Advice': 'Talent Attraction Advice',
    "SEEK's Talent Attraction Lab": 'SEEK Talent Attraction Lab',
    'Uncover the drivers that attract candidates to a role.':
      'Uncover the drivers that attract candidates to a role.',
    'Top 3 drivers of attraction for': 'Top 3 drivers of attraction for',
    "What's a must-have? An essential factor candidates expect a job to have.":
      "*What's a must-have? An essential factor candidates expect a job to have.",
    "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:":
      "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:",
    'Mention these top three drivers of attraction in your job ad.':
      'Mention these top three drivers of attraction in your job ad.',
    "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.":
      "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.",
    'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.':
      'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.',
    'Visit www.seek.com.au/tal to discover a full list of detailed drivers.':
      'Visit <Link>www.seek.com.au/tal</Link> to discover a full list of detailed drivers.',
    'About this research': 'About this research',
    'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ':
      'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ',
    'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit {link}':
      'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit <Link>link</Link>.',
    'Within this, secondary driver is the number one must-have for candidates.':
      'Within this, {driver} is the number one must-have* for candidates.',
    'Data snapshot': 'Data snapshot',
  }),
  'en-PH': createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Select filters to uncover deeper insights about candidates in {country}.',
    'Explore drivers by': 'Explore drivers by',
    'Select at least one option': 'Select at least one option',
    Share: 'Share',
    'Share on Facebook': 'Share on Facebook',
    'Share on X': 'Share on X',
    'Share on LinkedIn': 'Share on LinkedIn',
    'Copy Link': 'Copy Link',
    'Link copied to clipboard': 'Link copied to clipboard',
    'Clipboard access not available': 'Clipboard access not available',
    'Download PDF': 'Download PDF',
    'Showing results from': 'Showing results from',
    'All respondents': 'All respondents',
    'Showing results that match': 'Showing results that match',
    'Results are based on': 'Results are based on',
    'respondents from a total of': 'respondents from a total of',
    'candidates surveyed': 'candidates surveyed',
    'Drivers that attract these candidates':
      'Explore Key Talent Trends and Preferences',
    'About these candidates': 'Candidate Demographics and Behavior',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.",
    "We'll show this data when we have more responses from people who match your search.":
      "We'll show this data when we have more responses from people who match your search.",
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.",
    'In the meantime, you can adjust your filters to try a broader search.':
      'In the meantime, you can adjust your filters to try a broader search.',
    'These are the drivers candidates consider when looking at roles.':
      'These are the drivers candidates consider when looking at roles.',
    'Drivers are ranked with the most important driver at the top.':
      'Drivers are ranked with the most important driver at the top.',
    'Expand each driver for more detail.':
      'Expand each driver for more detail.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      "No data available yet for {filter}. We'll show this when we have enough responses.",
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Sample size for {filter} is small, so there is <u>greater margin of error.</u>',
    'When writing job ads':
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Candidates have ranked each driver according to 4 levels of importance:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Must-have: Essentials that candidates expect a job to have.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Delighted-if: Extras that differentiate you from other companies.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Not-bothered: Factors that don’t really matter to candidates.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Put-off: Dealbreakers that candidates don’t want in a role.',
    'Must-have': 'Must-have',
    'Delighted-if': 'Delighted-if',
    'Not-bothered': 'Not-bothered',
    'Put-off': 'Put-off',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'This is how candidates sort their preferences for the components of {filter}. Candidates have the option to choose more than one must-have.',
    'Refine data': 'Refine data',
    'This sample size is statistically significant for the filters you have chosen.':
      'This sample size is statistically significant for the filters you have chosen.',
    'Download report': 'Download report',
    'January 2023 to February 2024': 'January 2023 to February 2024',
    'April 2024 to May 2024': 'April 2024 to May 2024',
    'Talent Attraction Advice': 'Talent Attraction Advice',
    "SEEK's Talent Attraction Lab": 'SEEK Talent Attraction Lab',
    'Uncover the drivers that attract candidates to a role.':
      'Uncover the drivers that attract candidates to a role.',
    'Top 3 drivers of attraction for': 'Top 3 drivers of attraction for',
    "What's a must-have? An essential factor candidates expect a job to have.":
      "*What's a must-have? An essential factor candidates expect a job to have.",
    "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:":
      "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:",
    'Mention these top three drivers of attraction in your job ad.':
      'Mention these top three drivers of attraction in your job ad.',
    "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.":
      "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.",
    'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.':
      'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.',
    'Visit www.seek.com.au/tal to discover a full list of detailed drivers.':
      'Visit <Link>www.seek.com.au/tal</Link> to discover a full list of detailed drivers.',
    'About this research': 'About this research',
    'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ':
      'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ',
    'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit {link}':
      'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit <Link>link</Link>.',
    'Within this, secondary driver is the number one must-have for candidates.':
      'Within this, {driver} is the number one must-have* for candidates.',
    'Data snapshot': 'Data snapshot',
  }),
  'en-SG': createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Select filters to uncover deeper insights about candidates in {country}.',
    'Explore drivers by': 'Explore drivers by',
    'Select at least one option': 'Select at least one option',
    Share: 'Share',
    'Share on Facebook': 'Share on Facebook',
    'Share on X': 'Share on X',
    'Share on LinkedIn': 'Share on LinkedIn',
    'Copy Link': 'Copy Link',
    'Link copied to clipboard': 'Link copied to clipboard',
    'Clipboard access not available': 'Clipboard access not available',
    'Download PDF': 'Download PDF',
    'Showing results from': 'Showing results from',
    'All respondents': 'All respondents',
    'Showing results that match': 'Showing results that match',
    'Results are based on': 'Results are based on',
    'respondents from a total of': 'respondents from a total of',
    'candidates surveyed': 'candidates surveyed',
    'Drivers that attract these candidates':
      'Explore Key Talent Trends and Preferences',
    'About these candidates': 'Candidate Demographics and Behavior',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.",
    "We'll show this data when we have more responses from people who match your search.":
      "We'll show this data when we have more responses from people who match your search.",
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.",
    'In the meantime, you can adjust your filters to try a broader search.':
      'In the meantime, you can adjust your filters to try a broader search.',
    'These are the drivers candidates consider when looking at roles.':
      'These are the drivers candidates consider when looking at roles.',
    'Drivers are ranked with the most important driver at the top.':
      'Drivers are ranked with the most important driver at the top.',
    'Expand each driver for more detail.':
      'Expand each driver for more detail.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      "No data available yet for {filter}. We'll show this when we have enough responses.",
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Sample size for {filter} is small, so there is <u>greater margin of error.</u>',
    'When writing job ads':
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Candidates have ranked each driver according to 4 levels of importance:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Must-have: Essentials that candidates expect a job to have.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Delighted-if: Extras that differentiate you from other companies.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Not-bothered: Factors that don’t really matter to candidates.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Put-off: Dealbreakers that candidates don’t want in a role.',
    'Must-have': 'Must-have',
    'Delighted-if': 'Delighted-if',
    'Not-bothered': 'Not-bothered',
    'Put-off': 'Put-off',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'This is how candidates sort their preferences for the components of {filter}. Candidates have the option to choose more than one must-have.',
    'Refine data': 'Refine data',
    'This sample size is statistically significant for the filters you have chosen.':
      'This sample size is statistically significant for the filters you have chosen.',
    'Download report': 'Download report',
    'January 2023 to February 2024': 'January 2023 to February 2024',
    'April 2024 to May 2024': 'April 2024 to May 2024',
    'Talent Attraction Advice': 'Talent Attraction Advice',
    "SEEK's Talent Attraction Lab": 'SEEK Talent Attraction Lab',
    'Uncover the drivers that attract candidates to a role.':
      'Uncover the drivers that attract candidates to a role.',
    'Top 3 drivers of attraction for': 'Top 3 drivers of attraction for',
    "What's a must-have? An essential factor candidates expect a job to have.":
      "*What's a must-have? An essential factor candidates expect a job to have.",
    "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:":
      "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:",
    'Mention these top three drivers of attraction in your job ad.':
      'Mention these top three drivers of attraction in your job ad.',
    "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.":
      "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.",
    'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.':
      'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.',
    'Visit www.seek.com.au/tal to discover a full list of detailed drivers.':
      'Visit <Link>www.seek.com.au/tal</Link> to discover a full list of detailed drivers.',
    'About this research': 'About this research',
    'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ':
      'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ',
    'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit {link}':
      'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit <Link>link</Link>.',
    'Within this, secondary driver is the number one must-have for candidates.':
      'Within this, {driver} is the number one must-have* for candidates.',
    'Data snapshot': 'Data snapshot',
  }),
  'en-TH': createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Select filters to uncover deeper insights about candidates in {country}.',
    'Explore drivers by': 'Explore drivers by',
    'Select at least one option': 'Select at least one option',
    Share: 'Share',
    'Share on Facebook': 'Share on Facebook',
    'Share on X': 'Share on X',
    'Share on LinkedIn': 'Share on LinkedIn',
    'Copy Link': 'Copy Link',
    'Link copied to clipboard': 'Link copied to clipboard',
    'Clipboard access not available': 'Clipboard access not available',
    'Download PDF': 'Download PDF',
    'Showing results from': 'Showing results from',
    'All respondents': 'All respondents',
    'Showing results that match': 'Showing results that match',
    'Results are based on': 'Results are based on',
    'respondents from a total of': 'respondents from a total of',
    'candidates surveyed': 'candidates surveyed',
    'Drivers that attract these candidates':
      'Explore Key Talent Trends and Preferences',
    'About these candidates': 'Candidate Demographics and Behavior',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.",
    "We'll show this data when we have more responses from people who match your search.":
      "We'll show this data when we have more responses from people who match your search.",
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.",
    'In the meantime, you can adjust your filters to try a broader search.':
      'In the meantime, you can adjust your filters to try a broader search.',
    'These are the drivers candidates consider when looking at roles.':
      'These are the drivers candidates consider when looking at roles.',
    'Drivers are ranked with the most important driver at the top.':
      'Drivers are ranked with the most important driver at the top.',
    'Expand each driver for more detail.':
      'Expand each driver for more detail.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      "No data available yet for {filter}. We'll show this when we have enough responses.",
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Sample size for {filter} is small, so there is <u>greater margin of error.</u>',
    'When writing job ads':
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Candidates have ranked each driver according to 4 levels of importance:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Must-have: Essentials that candidates expect a job to have.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Delighted-if: Extras that differentiate you from other companies.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Not-bothered: Factors that don’t really matter to candidates.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Put-off: Dealbreakers that candidates don’t want in a role.',
    'Must-have': 'Must-have',
    'Delighted-if': 'Delighted-if',
    'Not-bothered': 'Not-bothered',
    'Put-off': 'Put-off',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'This is how candidates sort their preferences for the components of {filter}. Candidates have the option to choose more than one must-have.',
    'Refine data': 'Refine data',
    'This sample size is statistically significant for the filters you have chosen.':
      'This sample size is statistically significant for the filters you have chosen.',
    'Download report': 'Download report',
    'January 2023 to February 2024': 'January 2023 to February 2024',
    'April 2024 to May 2024': 'April 2024 to May 2024',
    'Talent Attraction Advice': 'Talent Attraction Advice',
    "SEEK's Talent Attraction Lab": 'SEEK Talent Attraction Lab',
    'Uncover the drivers that attract candidates to a role.':
      'Uncover the drivers that attract candidates to a role.',
    'Top 3 drivers of attraction for': 'Top 3 drivers of attraction for',
    "What's a must-have? An essential factor candidates expect a job to have.":
      "*What's a must-have? An essential factor candidates expect a job to have.",
    "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:":
      "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:",
    'Mention these top three drivers of attraction in your job ad.':
      'Mention these top three drivers of attraction in your job ad.',
    "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.":
      "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.",
    'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.':
      'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.',
    'Visit www.seek.com.au/tal to discover a full list of detailed drivers.':
      'Visit <Link>www.seek.com.au/tal</Link> to discover a full list of detailed drivers.',
    'About this research': 'About this research',
    'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ':
      'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ',
    'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit {link}':
      'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit <Link>link</Link>.',
    'Within this, secondary driver is the number one must-have for candidates.':
      'Within this, {driver} is the number one must-have* for candidates.',
    'Data snapshot': 'Data snapshot',
  }),
  'th-TH': createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'เลือกตัวกรองเพื่อเข้าถึงข้อมูลเชิงลึกเกี่ยวกับผู้สมัครใน ไทย',
    'Explore drivers by': 'ค้นหาปัจจัยตาม',
    'Select at least one option': 'เลือกอย่างน้อย 1 ตัวเลือก',
    Share: 'แชร์บน',
    'Share on Facebook': 'แชร์บน Facebook',
    'Share on X': 'แชร์บน X',
    'Share on LinkedIn': 'แชร์บน LinkedIn',
    'Copy Link': 'คัดลองลิงก์',
    'Link copied to clipboard': 'คัดลอกลิงก์',
    'Clipboard access not available': 'ไม่สามารถเข้าถึงคลิปบอร์ดได้',
    'Download PDF': 'ดาวน์โหลด PDF',
    'Showing results from': 'แสดงผลลัพธ์จาก',
    'All respondents': 'ผู้ตอบแบบสอบถามทุกคน',
    'Showing results that match': 'แสดงผลลัพธ์ที่ตรงความต้องการ',
    'Results are based on': 'ผลลัพธ์อาจขึ้นอยู่กับผู้ตอบแบบสอบถาม',
    'respondents from a total of': 'คน จากผู้หางานทั้งหมด',
    'candidates surveyed': 'คนที่เข้าร่วมตอบแบบสอบถามในช่วง',
    'Drivers that attract these candidates':
      'เข้าใจถึงแนวโน้มและปัจจัยสำคัญที่มีผลต่อผู้หางาน',
    'About these candidates': 'ข้อมูลประชากรและพฤติกรรมของผู้หางาน',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "ตัวเลขเปอร์เซ็นต์นี้แสดงถึงลำดับความสำคัญของแต่ละปัจจัยที่มีอิทธิพล ปัจจัยทั้งหมดอาจมีความ 'สำคัญ' แต่สิ่งนี้จะช่วยให้คุณเข้าใจการจัดลำดับความสำคัญของแต่ละปัจจัยจากผู้ตอบแบบสอบถามที่พวกเขามุ่งเน้นในประกาศงานและการสื่อสาร",
    "We'll show this data when we have more responses from people who match your search.":
      'เราจะแสดงข้อมูลนี้หากมีข้อมูลเพิ่มเติมจากผู้หางานที่ตรงความต้องการของคุณ',
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      'เรากำลังรวบรวมคำตอบจากผู้หางานเพื่อให้มั่นใจว่าอะไรคือสิ่งที่ดึงดูดใจผู้สมัครในการสมัครงานตำแหน่งนั้นๆ เราจะอัปเดตข้อมูลเมื่อเราดำเนินการเสร็จสิ้น',
    'In the meantime, you can adjust your filters to try a broader search.':
      'ในขณะเดียวกัน คุณสามารถปรับแต่งการคัดกรองเพื่อทำให้การค้นหากว้างขึ้น',
    'These are the drivers candidates consider when looking at roles.':
      'นี่คือปัจจัยที่ผู้สมัครงานพิจารณาเมื่อมองหางานใหม่',
    'Drivers are ranked with the most important driver at the top.':
      'ปัจจัยเหล่านี้จะจัดเรียงตามลำดับความสำคัญต่อผู้หางานโดยปัจจัยที่มีอิทธิพลต่อผู้หางานมากที่สุดจะอยู่ด้านบน',
    'Expand each driver for more detail.':
      'คุณสามารถคลิกแสดงเนื้อหาเพื่อดูรายละเอียดเพิ่มเติม',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      'ยังไม่มีข้อมูลสำหรับ {filter}  เราจะแสดงข้อมูลนี้อีกครั้งหากมีรายละเอียดเพิ่มเติม',
    'Sample size for selected filters is small, so there is greater margin of error.':
      'ขนาดตัวอย่างสำหรับ {filter} มีจำนวนน้อยเกินไป <u>ดังนั้นข้อมูลอาจมีค่าความคลาดเคลื่อนสูง</u>',
    'When writing job ads':
      'เมื่อเขียนประกาศงาน ให้มุ่งเน้นไปที่ปัจจัยที่จำเป็นต้องมีและปัจจัยที่ดีถ้ามี เพื่อสร้างความโดดเด่นเหนือคู่แข่ง อย่าเสียเวลาไปกับปัจจัยที่ผู้หางานไม่ได้ให้ความสนใจและปัจจัยที่อาจนำไปสู่การปฏิเสธงาน',
    'How we define importance': 'คำอธิบายความสำคัญของปัจจัย',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'ผู้สมัครได้จัดอันดับของแต่ละปัจจัยโดยเรียงตาม 4 ระดับความสำคัญ',
    'Must-have: Essentials that candidates expect a job to have.':
      'สิ่งที่ต้องมี: ปัจจัยสำคัญที่ผู้สมัครคาดหวังว่าจำเป็นต้องมี',
    'Delighted-if: Extras that differentiate you from other companies.':
      'ดีถ้ามี: ปัจจัยที่ถ้าหากมีจะช่วยให้คุณแตกต่างจากบริษัทอื่น',
    "Not-bothered: Factors that don't really matter to candidates.":
      'ไม่สนใจ: ปัจจัยที่ผู้สมัครไม่ได้ให้ความสนใจ',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'ปฏิเสธ: ปัจจัยที่มีผลทำให้ผู้สมัครปฏิเสธข้อเสนองาน',
    'Must-have': 'สิ่งที่ต้องมี',
    'Delighted-if': 'ดีถ้ามี',
    'Not-bothered': 'ไม่สนใจ',
    'Put-off': 'ปฏิเสธ',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'นี่คือวิธีที่ผู้สมัครใช้เพื่อจัดอันดับความต้องการของตนเองสำหรับปัจจัยเกี่ยวกับ {filter} ผู้สมัครสามารถเลือกสิ่งที่ต้องมีได้มากกว่าหนึ่งตัวเลือก',
    'Refine data': 'คัดกรองข้อมูล',
    'This sample size is statistically significant for the filters you have chosen.':
      'ขนาดตัวอย่างนี้มีความสำคัญทางสถิติสำหรับตัวกรองที่คุณเลือก',
    'Download report': 'ดาวน์โหลดรายงาน',
    'January 2023 to February 2024': 'January 2023 to February 2024',
    'April 2024 to May 2024': 'เมษายน 2567 - พฤษภาคม 2567',
    'Talent Attraction Advice': 'คำแนะนำในการดึงดูดผู้หางานที่ใช่',
    "SEEK's Talent Attraction Lab": 'SEEK Talent Attraction Lab',
    'Uncover the drivers that attract candidates to a role.':
      'พบกับข้อมูลเชิงลึก อะไรคือปัจจัยที่ดึงดูดใจผู้หางานในแต่ละตำแหน่ง',
    'Top 3 drivers of attraction for': 'ปัจจัยสำคัญ 3 อันดับแรกสำหรับ',
    "What's a must-have? An essential factor candidates expect a job to have.":
      '*อะไรคือ สิ่งที่ต้องมี? นี่เป็นปัจจัยสำคัญที่ผู้สมัครคาดหวังว่าจำเป็นต้องมี',
    "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:":
      'Talent Attraction Lab ออกแบบมาเพื่อช่วยให้คุณเข้าใจผู้หางานมากยิ่งขึ้น ว่าอะไรคือสิ่งที่พวกเขาต้องการเมื่อมองหางานหรือต้องพิจารณาว่าจะทำงานกับองค์กรต่อไป คุณสามารถนำปัจจัยเหล่านี้ไปใช้งานเพื่อดึงดูดผู้สมัครที่ใช่ได้ดังนี้',
    'Mention these top three drivers of attraction in your job ad.':
      'กล่าวถึงปัจจัยสำคัญ 3 อันดับแรกในประกาศงานของคุณ',
    "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.":
      'ใช้ปัจจัยหลักเหล่านี้ในการสื่อสารกับผู้หางาน ไม่ว่าจะเป็นระหว่างการสัมภาษณ์หรือผ่านข้อความต่างๆ',
    'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.':
      'สื่อสารเกี่ยวกับปัจจัยสำคัญเหล่านี้เมื่อพูดถึงจุดเด่นหรือค่านิยมองค์กรของคุณ (EVP) เพื่อสร้างความโดดเด่นเหนือคู่แข่ง',
    'Visit www.seek.com.au/tal to discover a full list of detailed drivers.':
      'ค้นหาเพิ่มเติมเกี่ยวกับปัจจัยสำคัญเหล่านี้ได้ที่ <Link>www.seek.com.au/tal</Link>',
    'About this research': 'เกี่ยวกับการวิจัย',
    'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ':
      'ข้อมูลที่อ้างถึงในหน้านี้มาจากการสำรวจ SEEK Talent Attraction Lab ในระหว่างเดือนเมษายน - พฤษภาคม 2567.',
    'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit {link}':
      'ต้องการข้อมูลเพิ่มเติมเกี่ยวกับการสำรวจ SEEK Talent Attraction Lab รวมถึงข้อกำหนดและเงื่อนไขเกี่ยวกับการใช้งานข้อมูลนี้ สามารถดูเพิ่มเติมได้ที่ <Link>link</Link>.',
    'Within this, secondary driver is the number one must-have for candidates.':
      'ในปัจจัยนี้ {driver} คือสิ่งที่ต้องมี* สำหรับผู้หางาน.',
    'Data snapshot': 'สแนปชอตข้อมูล',
  }),
  'id-ID': createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Pilih filter untuk mendapatkan wawasan lebih dalam tentang kandidat Indonesia',
    'Explore drivers by': 'Cari tahu faktor berdasarkan',
    'Select at least one option': 'Pilih setidaknya satu opsi',
    Share: 'Bagikan',
    'Share on Facebook': 'Bagikan di Facebook',
    'Share on X': 'Bagikan di X',
    'Share on LinkedIn': 'Bagikan di LinkedIn',
    'Copy Link': 'Salin tautan',
    'Link copied to clipboard': 'Tautan disalin ke papan clip',
    'Clipboard access not available': 'Akses ke papan klik tidak tersedia',
    'Download PDF': 'Unduh PDF',
    'Showing results from': 'Menampilkan Hasil dari',
    'All respondents': 'Semua responden',
    'Showing results that match': 'Menampilkan hasil yang cocok',
    'Results are based on': 'Hasil berdasarkan',
    'respondents from a total of': 'responden dari total',
    'candidates surveyed': 'kandidat yang disurvei dalam',
    'Drivers that attract these candidates':
      'Pelajari Kunci Tentang Tren Kandidat Terkini dan Preferensinya',
    'About these candidates': 'Demografi dan Tipe Kandidat',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "Persentase ini adalah cara kandidat memberi peringkat faktor pendorong berdasarkan tingkat kepentingan. Semua faktor pendorong bisa jadi 'penting', tetapi ini membantu Anda memprioritaskan faktor mana untuk difokuskan dalam iklan pekerjaan atau percakapan.",
    "We'll show this data when we have more responses from people who match your search.":
      'Kami akan menampilkan data ini ketika kami memiliki jumlah respon yang cukup dari orang yang sesuai dengan pencarian Anda.',
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      'Kami berusaha mengumpulkan respon yang cukup dari para kandidat ini untuk berbagi apa yang membuat mereka tertarik pada suatu posisi dengan percaya diri. Kami akan memperbarui data ketika kami berhasil mengumpulkannya.',
    'In the meantime, you can adjust your filters to try a broader search.':
      'Sementara itu, Anda bisa menyesuaikan filter Anda untuk mencoba pencarian yang lebih luas.',
    'These are the drivers candidates consider when looking at roles.':
      'Ini adalah faktor pendorong yang dipertimbangkan kandidat saat melihat posisi.',
    'Drivers are ranked with the most important driver at the top.':
      'Faktor pendorong diurutkan dari yang terpenting di bagian atas.',
    'Expand each driver for more detail.':
      'Perluas setiap faktor untuk detail lebih lanjut.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      'Belum ada data yang tersedia untuk {filter}. Kami akan menunjukkan ini saat kami memiliki cukup respons.',
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Ukuran sampel untuk {filter} kecil, jadi ada <u>margin kesalahan yang lebih besar.</u>',
    'When writing job ads':
      'Saat menulis iklan lowongan kerja, fokuslah pada faktor-faktor pendorong utama yang menarik kandidat dan yang membedakan Anda dengan kompetitor. Jangan fokus pada faktor pendorong yang kurang menarik. ',
    'How we define importance':
      'Bagaimana kami mendefinisikan hal yang penting',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Kandidat telah diurutkan pada setiap faktor pendorong berdasarkan 4 tingkat kepentingan:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Harus dimiliki: Hal-hal penting yang diharapkan dimiliki kandidat dalam sebuah pekerjaan.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Bagus jika dimiliki: Hal-hal tambahan yang membedakan Anda dari perusahaan lain.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Tidak perlu: Faktor-faktor yang tidak terlalu penting bagi kandidat.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Penolakan: Hal-hal yang tidak diinginkan oleh kandidat dalam sebuah posisi.',
    'Must-have': 'Harus dimiliki',
    'Delighted-if': 'Bagus jika dimiliki',
    'Not-bothered': 'Tidak perlu',
    'Put-off': 'Penolakan',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'Ini adalah cara kandidat mengurutkan preferensi mereka untuk komponen {filter}. Kandidat memiliki opsi untuk memilih lebih dari satu yang harus dimiliki.',
    'Refine data': 'Perbaiki data',
    'This sample size is statistically significant for the filters you have chosen.':
      'Ukuran sampel data secara statistik berpengaruh secara signifikan terhadap filter-filter yang Anda pilih',
    'Download report': 'Unduh laporan',
    'January 2023 to February 2024': 'January 2023 to February 2024',
    'April 2024 to May 2024': 'April 2024 hingga Mei 2024',
    'Talent Attraction Advice': 'Saran Talent Attraction',
    "SEEK's Talent Attraction Lab": 'Talent Attraction Lab',
    'Uncover the drivers that attract candidates to a role.':
      'Mengungkap faktor-faktor yang menarik kandidat melamar pekerjaan.',
    'Top 3 drivers of attraction for':
      '3 faktor pendorong teratas menarik kandidat untuk',
    "What's a must-have? An essential factor candidates expect a job to have.":
      '*Apa yang harus dimiliki? Faktor penting yang diharapkan kandidat ada dalam sebuah pekerjaan.',
    "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:":
      'Talent Attraction Lab didesain untuk membantu Anda untuk memahami lebih baik apa yang kandidat inginkan saat memutuskan untuk bergabung atau bertahan di suatu perusahaan. Berikut cara yang dapat Anda lakukan untuk memanfaatkan faktor-faktor untuk menarik perhatian kandidat.',
    'Mention these top three drivers of attraction in your job ad.':
      'Sebutkan 3 faktor pendorong utama untuk menarik kandidat dalam iklan lowongan kerja Anda.',
    "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.":
      'Gunakan faktor-faktor pendorong berikut untuk membantu Anda terhubung dengan kandidat dengan cara yang tepat, baik dalam wawancara atau berkirim pesan.',
    'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.':
      'Sampaikan faktor-faktor pendorong tersebut dalam Employee Value Proposition (EVP) untuk membuat perusahaan Anda menonjol.',
    'Visit www.seek.com.au/tal to discover a full list of detailed drivers.':
      'Kunjungi <Link>www.seek.com.au/tal</Link> untuk melihat daftar lengkap faktor-faktor pendorong dengan terperinci.',
    'About this research': 'Tentang penilitian ini',
    'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ':
      'Poin-poin pada data yang disebutkan dalam halaman ini diambil dari survei Talent Attraction Lab yang dilakukan pada April-Mei 2024.',
    'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit {link}':
      'Untuk dapatkan informasi lebih lanjut tentang survei Talent Attraction Lab SEEK dan ketentuan serta kondisi yang memengaruhi penggunaan data tersebut, kunjungi <Link>www.seek.com.au/tal</Link>.',
    'Within this, secondary driver is the number one must-have for candidates.':
      'Dengan demikian, {driver} adalah hal yang paling utama* bagi kandidat.',
    'Data snapshot': 'Cuplikan data',
  }),
  'zh-HK': createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      'Select filters to uncover deeper insights about candidates in {country}.',
    'Explore drivers by': 'Explore drivers by',
    'Select at least one option': 'Select at least one option',
    Share: 'Share',
    'Share on Facebook': 'Share on Facebook',
    'Share on X': 'Share on X',
    'Share on LinkedIn': 'Share on LinkedIn',
    'Copy Link': 'Copy Link',
    'Link copied to clipboard': 'Link copied to clipboard',
    'Clipboard access not available': 'Clipboard access not available',
    'Download PDF': 'Download PDF',
    'Showing results from': 'Showing results from',
    'All respondents': 'All respondents',
    'Showing results that match': 'Showing results that match',
    'Results are based on': 'Results are based on',
    'respondents from a total of': 'respondents from a total of',
    'candidates surveyed': 'candidates surveyed',
    'Drivers that attract these candidates':
      'Explore Key Talent Trends and Preferences',
    'About these candidates': 'Candidate Demographics and Behavior',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.",
    "We'll show this data when we have more responses from people who match your search.":
      "We'll show this data when we have more responses from people who match your search.",
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.",
    'In the meantime, you can adjust your filters to try a broader search.':
      'In the meantime, you can adjust your filters to try a broader search.',
    'These are the drivers candidates consider when looking at roles.':
      'These are the drivers candidates consider when looking at roles.',
    'Drivers are ranked with the most important driver at the top.':
      'Drivers are ranked with the most important driver at the top.',
    'Expand each driver for more detail.':
      'Expand each driver for more detail.',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      "No data available yet for {filter}. We'll show this when we have enough responses.",
    'Sample size for selected filters is small, so there is greater margin of error.':
      'Sample size for {filter} is small, so there is <u>greater margin of error.</u>',
    'When writing job ads':
      "When writing job ads, focus on delighted-if and must-have drivers that differentiate you from your competitors. Don't waste space on not-bothered drivers, and highlight put-offs early. ",
    'How we define importance': 'How we define importance',
    'Candidates have ranked each driver according to 4 levels of importance:':
      'Candidates have ranked each driver according to 4 levels of importance:',
    'Must-have: Essentials that candidates expect a job to have.':
      'Must-have: Essentials that candidates expect a job to have.',
    'Delighted-if: Extras that differentiate you from other companies.':
      'Delighted-if: Extras that differentiate you from other companies.',
    "Not-bothered: Factors that don't really matter to candidates.":
      'Not-bothered: Factors that don’t really matter to candidates.',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      'Put-off: Dealbreakers that candidates don’t want in a role.',
    'Must-have': 'Must-have',
    'Delighted-if': 'Delighted-if',
    'Not-bothered': 'Not-bothered',
    'Put-off': 'Put-off',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      'This is how candidates sort their preferences for the components of {filter}. Candidates have the option to choose more than one must-have.',
    'Refine data': 'Refine data',
    'This sample size is statistically significant for the filters you have chosen.':
      'This sample size is statistically significant for the filters you have chosen.',
    'Download report': 'Download report',
    'January 2023 to February 2024': 'January 2023 to February 2024',
    'April 2024 to May 2024': 'April 2024 to May 2024',
    'Talent Attraction Advice': 'Talent Attraction Advice',
    "SEEK's Talent Attraction Lab": 'SEEK Talent Attraction Lab',
    'Uncover the drivers that attract candidates to a role.':
      'Uncover the drivers that attract candidates to a role.',
    'Top 3 drivers of attraction for': 'Top 3 drivers of attraction for',
    "What's a must-have? An essential factor candidates expect a job to have.":
      "*What's a must-have? An essential factor candidates expect a job to have.",
    "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:":
      "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:",
    'Mention these top three drivers of attraction in your job ad.':
      'Mention these top three drivers of attraction in your job ad.',
    "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.":
      "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.",
    'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.':
      'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.',
    'Visit www.seek.com.au/tal to discover a full list of detailed drivers.':
      'Visit <Link>www.seek.com.au/tal</Link> to discover a full list of detailed drivers.',
    'About this research': 'About this research',
    'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ':
      'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ',
    'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit {link}':
      'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit <Link>link</Link>.',
    'Within this, secondary driver is the number one must-have for candidates.':
      'Within this, {driver} is the number one must-have* for candidates.',
    'Data snapshot': 'Data snapshot',
  }),
  'en-PSEUDO': createLanguage({
    'Select filters to uncover deeper insights about candidates in country.':
      '[Šẽẽẽƚẽẽẽçṯ ƒìììƚṯẽẽẽřš ṯööö ǚǚǚกี้çöööṽẽẽẽř ƌẽẽẽẽẽẽƥẽẽẽř ìììกี้šìììģḩṯš ăăăßöööǚǚǚṯ çăăăกี้ƌìììƌăăăṯẽẽẽš ìììกี้ {country}.]',
    'Explore drivers by': '[Ëꭕƥƚöööřẽẽẽ ƌřìììṽẽẽẽřš ßýýý]',
    'Select at least one option':
      '[Šẽẽẽƚẽẽẽçṯ ăăăṯ ƚẽẽẽăăăšṯ öööกี้ẽẽẽ öööƥṯìììöööกี้]',
    Share: '[Šḩăăřẽẽ]',
    'Share on Facebook': '[Šḩăăřẽẽ ööกี้ Ƒăăçẽẽßööööķ]',
    'Share on X': '[Šḩăăăřẽẽẽ öööกี้ X̂]',
    'Share on LinkedIn': '[Šḩăăăřẽẽẽ öööกี้ Łìììกี้ķẽẽẽƌÏกี้]',
    'Copy Link': '[Çöööƥýýý Łìììกี้ķ]',
    'Link copied to clipboard': '[Łìììกี้ķ çöööƥìììẽẽẽƌ ṯööö çƚìììƥßöööăăăřƌ]',
    'Clipboard access not available':
      '[Çƚìììƥßöööăăăřƌ ăăăççẽẽẽšš กี้öööṯ ăăăṽăăăìììƚăăăßƚẽẽẽ]',
    'Download PDF': '[Ḋöööŵกี้ƚöööăăăƌ ƤḊƑ]',
    'Showing results from': '[Šḩöööŵìììกี้ģ řẽẽẽšǚǚǚƚṯš ƒřöööm̂]',
    'All respondents': '[Ậƚƚ řẽẽẽšƥöööกี้ƌẽẽẽกี้ṯš]',
    'Showing results that match': '[Šḩöööŵìììกี้ģ řẽẽẽšǚǚǚƚṯš ṯḩăăăṯ m̂ăăăṯçḩ]',
    'Results are based on': '[Řẽẽẽšǚǚǚƚṯš ăăăřẽẽẽ ßăăăšẽẽẽƌ öööกี้]',
    'respondents from a total of':
      '[řẽẽẽšƥöööกี้ƌẽẽẽกี้ṯš ƒřöööm̂ ăăă ṯöööṯăăăƚ öööƒ]',
    'candidates surveyed': '[çăăกี้ƌììƌăăṯẽẽš šǚǚřṽẽẽýýẽẽƌ]',
    'Drivers that attract these candidates':
      '[Ëꭕƥƚöööřẽẽẽ Ḱẽẽẽýýý Ṯăăăƚẽẽẽกี้ṯ Ṯřẽẽẽกี้ƌš ăăăกี้ƌ Ƥřẽẽẽƒẽẽẽřẽẽẽกี้çẽẽẽš]',
    'About these candidates':
      '[Çăăăกี้ƌìììƌăăăṯẽẽẽ Ḋẽẽẽm̂öööģřăăăƥḩìììçš ăăăกี้ƌ ẞẽẽẽḩăăăṽìììöööř]',
    "This % is how candidates rank the driver on importance. All drivers could be 'important' but this helps you prioritise the drivers to focus on in job ads or conversations.":
      "[Ṯḩìììš % ìììš ḩöööŵ çăăăกี้ƌìììƌăăăṯẽẽẽš řăăăกี้ķ ṯḩẽẽẽ ƌřìììṽẽẽẽř öööกี้ ìììm̂ƥöööřṯăăăกี้çẽẽẽ. Ậƚƚ ƌřìììṽẽẽẽřš çöööǚǚǚƚƌ ßẽẽẽ 'ìììm̂ƥöööřṯăăăกี้ṯ' ßǚǚǚṯ ṯḩìììš ḩẽẽẽƚƥš ýýýöööǚǚǚ ƥřìììöööřìììṯìììšẽẽẽ ṯḩẽẽẽ ƌřìììṽẽẽẽřš ṯööö ƒöööçǚǚǚš öööกี้ ìììกี้ ĵöööß ăăăƌš öööř çöööกี้ṽẽẽẽřšăăăṯìììöööกี้š.]",
    "We'll show this data when we have more responses from people who match your search.":
      "[Ŵẽẽẽ'ƚƚ šḩöööŵ ṯḩìììš ƌăăăṯăăă ŵḩẽẽẽกี้ ŵẽẽẽ ḩăăăṽẽẽẽ m̂öööřẽẽẽ řẽẽẽšƥöööกี้šẽẽẽš ƒřöööm̂ ƥẽẽẽöööƥƚẽẽẽ ŵḩööö m̂ăăăṯçḩ ýýýöööǚǚǚř šẽẽẽăăăřçḩ.]",
    "We’re working on gathering enough responses from these candidates to confidently share what attracts them to a role. We'll update the data when we do.":
      "[Ŵẽẽẽ’řẽẽẽ ŵöööřķìììกี้ģ öööกี้ ģăăăṯḩẽẽẽřìììกี้ģ ẽẽẽกี้öööǚǚǚģḩ řẽẽẽšƥöööกี้šẽẽẽš ƒřöööm̂ ṯḩẽẽẽšẽẽẽ çăăăกี้ƌìììƌăăăṯẽẽẽš ṯööö çöööกี้ƒìììƌẽẽẽกี้ṯƚýýý šḩăăăřẽẽẽ ŵḩăăăṯ ăăăṯṯřăăăçṯš ṯḩẽẽẽm̂ ṯööö ăăă řöööƚẽẽẽ. Ŵẽẽẽ'ƚƚ ǚǚǚƥƌăăăṯẽẽẽ ṯḩẽẽẽ ƌăăăṯăăă ŵḩẽẽẽกี้ ŵẽẽẽ ƌööö.]",
    'In the meantime, you can adjust your filters to try a broader search.':
      '[Ïกี้ ṯḩẽẽẽ m̂ẽẽẽăăăกี้ṯìììm̂ẽẽẽ, ýýýöööǚǚǚ çăăăกี้ ăăăƌĵǚǚǚšṯ ýýýöööǚǚǚř ƒìììƚṯẽẽẽřš ṯööö ṯřýýý ăăă ßřöööăăăƌẽẽẽř šẽẽẽăăăřçḩ.]',
    'These are the drivers candidates consider when looking at roles.':
      '[Ṯḩẽẽẽšẽẽẽ ăăăřẽẽẽ ṯḩẽẽẽ ƌřìììṽẽẽẽřš çăăăกี้ƌìììƌăăăṯẽẽẽš çöööกี้šìììƌẽẽẽř ŵḩẽẽẽกี้ ƚööööööķìììกี้ģ ăăăṯ řöööƚẽẽẽš.]',
    'Drivers are ranked with the most important driver at the top.':
      '[Ḋřìììṽẽẽẽřš ăăăřẽẽẽ řăăăกี้ķẽẽẽƌ ŵìììṯḩ ṯḩẽẽẽ m̂öööšṯ ìììm̂ƥöööřṯăăăกี้ṯ ƌřìììṽẽẽẽř ăăăṯ ṯḩẽẽẽ ṯöööƥ.]',
    'Expand each driver for more detail.':
      '[Ëꭕƥăăăกี้ƌ ẽẽẽăăăçḩ ƌřìììṽẽẽẽř ƒöööř m̂öööřẽẽẽ ƌẽẽẽṯăăăìììƚ.]',
    "No data available yet for selected filters. We'll show this when we have enough responses.":
      "[Ñöö ƌăăṯăă ăăṽăăììƚăăßƚẽẽ ýýẽẽṯ ƒööř {filter}. Ŵẽẽẽ'ƚƚ šḩöööŵ ṯḩìììš ŵḩẽẽẽกี้ ŵẽẽẽ ḩăăăṽẽẽẽ ẽẽẽกี้öööǚǚǚģḩ řẽẽẽšƥöööกี้šẽẽẽš.]",
    'Sample size for selected filters is small, so there is greater margin of error.':
      '[Šăăăm̂ƥƚẽẽẽ šìììƶẽẽẽ ƒöööř {filter} ìììš šm̂ăăăƚƚ, šööö ṯḩẽẽẽřẽẽẽ ìììš <u>ģřẽẽẽăăăṯẽẽẽř m̂ăăăřģìììกี้ öööƒ ẽẽẽřřöööř.</u>]',
    'When writing job ads':
      "[Ŵḩẽẽẽกี้ ŵřìììṯìììกี้ģ ĵöööß ăăăƌš, ƒöööçǚǚǚš öööกี้ ƌẽẽẽƚìììģḩṯẽẽẽƌ-ìììƒ ăăăกี้ƌ m̂ǚǚǚšṯ-ḩăăăṽẽẽẽ ƌřìììṽẽẽẽřš ṯḩăăăṯ ƌìììƒƒẽẽẽřẽẽẽกี้ṯìììăăăṯẽẽẽ ýýýöööǚǚǚ ƒřöööm̂ ýýýöööǚǚǚř çöööm̂ƥẽẽẽṯìììṯöööřš. Ḋöööกี้'ṯ ŵăăăšṯẽẽẽ šƥăăăçẽẽẽ öööกี้ กี้öööṯ-ßöööṯḩẽẽẽřẽẽẽƌ ƌřìììṽẽẽẽřš, ăăăกี้ƌ ḩìììģḩƚìììģḩṯ ƥǚǚǚṯ-öööƒƒš ẽẽẽăăăřƚýýý. ]",
    'How we define importance':
      '[Ḩöööŵ ŵẽẽẽ ƌẽẽẽƒìììกี้ẽẽẽ ìììm̂ƥöööřṯăăăกี้çẽẽẽ]',
    'Candidates have ranked each driver according to 4 levels of importance:':
      '[Çăăăกี้ƌìììƌăăăṯẽẽẽš ḩăăăṽẽẽẽ řăăăกี้ķẽẽẽƌ ẽẽẽăăăçḩ ƌřìììṽẽẽẽř ăăăççöööřƌìììกี้ģ ṯööö 4 ƚẽẽẽṽẽẽẽƚš öööƒ ìììm̂ƥöööřṯăăăกี้çẽẽẽ:]',
    'Must-have: Essentials that candidates expect a job to have.':
      '[Ṃǚǚǚšṯ-ḩăăăṽẽẽẽ: Ëššẽẽẽกี้ṯìììăăăƚš ṯḩăăăṯ çăăăกี้ƌìììƌăăăṯẽẽẽš ẽẽẽꭕƥẽẽẽçṯ ăăă ĵöööß ṯööö ḩăăăṽẽẽẽ.]',
    'Delighted-if: Extras that differentiate you from other companies.':
      '[Ḋẽẽẽƚìììģḩṯẽẽẽƌ-ìììƒ: Ëꭕṯřăăăš ṯḩăăăṯ ƌìììƒƒẽẽẽřẽẽẽกี้ṯìììăăăṯẽẽẽ ýýýöööǚǚǚ ƒřöööm̂ öööṯḩẽẽẽř çöööm̂ƥăăăกี้ìììẽẽẽš.]',
    "Not-bothered: Factors that don't really matter to candidates.":
      '[Ñöööṯ-ßöööṯḩẽẽẽřẽẽẽƌ: Ƒăăăçṯöööřš ṯḩăăăṯ ƌöööกี้’ṯ řẽẽẽăăăƚƚýýý m̂ăăăṯṯẽẽẽř ṯööö çăăăกี้ƌìììƌăăăṯẽẽẽš.]',
    "Put-off: Dealbreakers that candidates don't want in a role.":
      '[Ƥǚǚǚṯ-öööƒƒ: Ḋẽẽẽăăăƚßřẽẽẽăăăķẽẽẽřš ṯḩăăăṯ çăăăกี้ƌìììƌăăăṯẽẽẽš ƌöööกี้’ṯ ŵăăăกี้ṯ ìììกี้ ăăă řöööƚẽẽẽ.]',
    'Must-have': '[Ṃǚǚǚšṯ-ḩăăăṽẽẽẽ]',
    'Delighted-if': '[Ḋẽẽẽƚìììģḩṯẽẽẽƌ-ìììƒ]',
    'Not-bothered': '[Ñöööṯ-ßöööṯḩẽẽẽřẽẽẽƌ]',
    'Put-off': '[Ƥǚǚǚṯ-öööƒƒ]',
    'This is how candidates sort their preferences for the components of selected filter. Candidates have the option to choose more than one must-have.':
      '[Ṯḩìììš ìììš ḩöööŵ çăăăกี้ƌìììƌăăăṯẽẽẽš šöööřṯ ṯḩẽẽẽìììř ƥřẽẽẽƒẽẽẽřẽẽẽกี้çẽẽẽš ƒöööř ṯḩẽẽẽ çöööm̂ƥöööกี้ẽẽẽกี้ṯš öööƒ {filter}. Çăăăกี้ƌìììƌăăăṯẽẽẽš ḩăăăṽẽẽẽ ṯḩẽẽẽ öööƥṯìììöööกี้ ṯööö çḩööööööšẽẽẽ m̂öööřẽẽẽ ṯḩăăăกี้ öööกี้ẽẽẽ m̂ǚǚǚšṯ-ḩăăăṽẽẽẽ.]',
    'Refine data': '[Řẽẽƒììกี้ẽẽ ƌăăṯăă]',
    'This sample size is statistically significant for the filters you have chosen.':
      '[Ṯḩìììš šăăăm̂ƥƚẽẽẽ šìììƶẽẽẽ ìììš šṯăăăṯìììšṯìììçăăăƚƚýýý šìììģกี้ìììƒìììçăăăกี้ṯ ƒöööř ṯḩẽẽẽ ƒìììƚṯẽẽẽřš ýýýöööǚǚǚ ḩăăăṽẽẽẽ çḩöööšẽẽẽกี้.]',
    'Download report': '[Ḋöööŵกี้ƚöööăăăƌ řẽẽẽƥöööřṯ]',
    'January 2023 to February 2024':
      '[Ĵăăăกี้ǚǚǚăăăřýýý 2023 ṯööö Ƒẽẽẽßřǚǚǚăăăřýýý 2024]',
    'April 2024 to May 2024': '[Ậƥřììììƚ 2024 ṯöööö Ṃăăăăýýýý 2024]',
    'Talent Attraction Advice': '[Ṯăăăƚẽẽẽกี้ṯ Ậṯṯřăăăçṯìììöööกี้ Ậƌṽìììçẽẽẽ]',
    "SEEK's Talent Attraction Lab":
      '[ŠËËḰ Ṯăăăƚẽẽẽกี้ṯ Ậṯṯřăăăçṯìììöööกี้ Łăăăß]',
    'Uncover the drivers that attract candidates to a role.':
      '[Ǚกี้çöööṽẽẽẽř ṯḩẽẽẽ ƌřìììṽẽẽẽřš ṯḩăăăṯ ăăăṯṯřăăăçṯ çăăăกี้ƌìììƌăăăṯẽẽẽš ṯööö ăăă řöööƚẽẽẽ.]',
    'Top 3 drivers of attraction for':
      '[Ṯöööƥ 3 ƌřìììṽẽẽẽřš öööƒ ăăăṯṯřăăăçṯìììöööกี้ ƒöööř]',
    "What's a must-have? An essential factor candidates expect a job to have.":
      "[*Ŵḩăăăṯ'š ăăă m̂ǚǚǚšṯ-ḩăăăṽẽẽẽ? Ậกี้ ẽẽẽššẽẽẽกี้ṯìììăăăƚ ƒăăăçṯöööř çăăăกี้ƌìììƌăăăṯẽẽẽš ẽẽẽꭕƥẽẽẽçṯ ăăă ĵöööß ṯööö ḩăăăṽẽẽẽ.]",
    "Talent Attraction Lab is designed to help you better understand what candidates really want when looking to join or stay with a business. Here's how you can use these drivers of attraction to appeal to candidates:":
      "[Ṯăăăƚẽẽẽกี้ṯ Ậṯṯřăăăçṯìììöööกี้ Łăăăß ìììš ƌẽẽẽšìììģกี้ẽẽẽƌ ṯööö ḩẽẽẽƚƥ ýýýöööǚǚǚ ßẽẽẽṯṯẽẽẽř ǚǚǚกี้ƌẽẽẽřšṯăăăกี้ƌ ŵḩăăăṯ çăăăกี้ƌìììƌăăăṯẽẽẽš řẽẽẽăăăƚƚýýý ŵăăăกี้ṯ ŵḩẽẽẽกี้ ƚööööööķìììกี้ģ ṯööö ĵöööìììกี้ öööř šṯăăăýýý ŵìììṯḩ ăăă ßǚǚǚšìììกี้ẽẽẽšš. Ḩẽẽẽřẽẽẽ'š ḩöööŵ ýýýöööǚǚǚ çăăăกี้ ǚǚǚšẽẽẽ ṯḩẽẽẽšẽẽẽ ƌřìììṽẽẽẽřš öööƒ ăăăṯṯřăăăçṯìììöööกี้ ṯööö ăăăƥƥẽẽẽăăăƚ ṯööö çăăăกี้ƌìììƌăăăṯẽẽẽš:]",
    'Mention these top three drivers of attraction in your job ad.':
      '[Ṃẽẽẽกี้ṯìììöööกี้ ṯḩẽẽẽšẽẽẽ ṯöööƥ ṯḩřẽẽẽẽẽẽ ƌřìììṽẽẽẽřš öööƒ ăăăṯṯřăăăçṯìììöööกี้ ìììกี้ ýýýöööǚǚǚř ĵöööß ăăăƌ.]',
    "Use these drivers to help you speak to candidates in ways that resonate with them, whether it's in interviews or messages.":
      "[Ǚšẽẽẽ ṯḩẽẽẽšẽẽẽ ƌřìììṽẽẽẽřš ṯööö ḩẽẽẽƚƥ ýýýöööǚǚǚ šƥẽẽẽăăăķ ṯööö çăăăกี้ƌìììƌăăăṯẽẽẽš ìììกี้ ŵăăăýýýš ṯḩăăăṯ řẽẽẽšöööกี้ăăăṯẽẽẽ ŵìììṯḩ ṯḩẽẽẽm̂, ŵḩẽẽẽṯḩẽẽẽř ìììṯ'š ìììกี้ ìììกี้ṯẽẽẽřṽìììẽẽẽŵš öööř m̂ẽẽẽššăăăģẽẽẽš.]",
    'Communicate these drivers in your Employee Value Proposition (EVP) to make your organisation stand out.':
      '[Çöööm̂m̂ǚǚǚกี้ìììçăăăṯẽẽẽ ṯḩẽẽẽšẽẽẽ ƌřìììṽẽẽẽřš ìììกี้ ýýýöööǚǚǚř Ëm̂ƥƚöööýýýẽẽẽẽẽẽ Ṽăăăƚǚǚǚẽẽẽ Ƥřöööƥöööšìììṯìììöööกี้ (ËṼƤ) ṯööö m̂ăăăķẽẽẽ ýýýöööǚǚǚř öööřģăăăกี้ìììšăăăṯìììöööกี้ šṯăăăกี้ƌ öööǚǚǚṯ.]',
    'Visit www.seek.com.au/tal to discover a full list of detailed drivers.':
      '[Ṽìììšìììṯ <Link>ŵŵŵ.šẽẽẽẽẽẽķ.çöööm̂.ăăăǚǚǚ/ṯăăăƚ</Link> ṯööö ƌìììšçöööṽẽẽẽř ăăă ƒǚǚǚƚƚ ƚìììšṯ öööƒ ƌẽẽẽṯăăăìììƚẽẽẽƌ ƌřìììṽẽẽẽřš.]',
    'About this research': '[Ậßöööǚǚǚṯ ṯḩìììš řẽẽẽšẽẽẽăăăřçḩ]',
    'The data points referred to on this page are drawn from the SEEK Talent Attraction Lab survey from April - May 2024. ':
      '[Ṯḩẽẽẽ ƌăăăṯăăă ƥöööìììกี้ṯš řẽẽẽƒẽẽẽřřẽẽẽƌ ṯööö öööกี้ ṯḩìììš ƥăăăģẽẽẽ ăăăřẽẽẽ ƌřăăăŵกี้ ƒřöööm̂ ṯḩẽẽẽ ŠËËḰ Ṯăăăƚẽẽẽกี้ṯ Ậṯṯřăăăçṯìììöööกี้ Łăăăß šǚǚǚřṽẽẽẽýýý ƒřöööm̂ Ậƥřìììƚ - Ṃăăăýýý 2024. ]',
    'For more information about SEEK Talent Attraction Lab survey and the terms and conditions governing the use of this data, visit {link}':
      '[Ƒöööř m̂öööřẽẽẽ ìììกี้ƒöööřm̂ăăăṯìììöööกี้ ăăăßöööǚǚǚṯ ŠËËḰ Ṯăăăƚẽẽẽกี้ṯ Ậṯṯřăăăçṯìììöööกี้ Łăăăß šǚǚǚřṽẽẽẽýýý ăăăกี้ƌ ṯḩẽẽẽ ṯẽẽẽřm̂š ăăăกี้ƌ çöööกี้ƌìììṯìììöööกี้š ģöööṽẽẽẽřกี้ìììกี้ģ ṯḩẽẽẽ ǚǚǚšẽẽẽ öööƒ ṯḩìììš ƌăăăṯăăă, ṽìììšìììṯ <Link>ƚìììกี้ķ</Link>.]',
    'Within this, secondary driver is the number one must-have for candidates.':
      '[Ŵìììṯḩìììกี้ ṯḩìììš, {driver} ìììš ṯḩẽẽẽ กี้ǚǚǚm̂ßẽẽẽř öööกี้ẽẽẽ m̂ǚǚǚšṯ-ḩăăăṽẽẽẽ* ƒöööř çăăăกี้ƌìììƌăăăṯẽẽẽš.]',
    'Data snapshot': '[Ḋăăăṯăăă šกี้ăăăƥšḩöööṯ]',
  }),
});

export default translations;
