import "../../libs/components/src/modules/Banner/styles.css.ts.vanilla.css!=!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.16_@types+node@20.14.9_babel-plugin-macros@3.1.0_webpack@_a4wh3t7jwjopzbsr5zdon56pg4/node_modules/@vanilla-extract/webpack-plugin/virtualFileLoader/dist/vanilla-extract-webpack-plugin-virtualFileLoader.cjs.js?{\"fileName\":\"../../libs/components/src/modules/Banner/styles.css.ts.vanilla.css\",\"source\":\"#H4sIAAAAAAAAA61STXOCMBC9+yt2ptMZPcRBFC146al/w4lkxVVImBAU7fjfO8TQIkXtoVwg8Hgf+3a8Cnd6KtcT+BwAGKwM4yklMoIYpUG9HABskZKtiWDiea/1OeMVO5Iw29tXDWzmeXm1HFwGY8ft/x/3tMs9fcC95vE+0aqUgmnMkZsIpHKPne8FnTGCWEnDSdbfnucDODOSAqsIwrBtaWYttchjlSodwcuHvfAGHNwBH7geMraa+PoQ741fjqxhpQVqprmgsuhi4lGbd2F5BRV5yk8RbFK0lndlYWhzYnVSlOZREzkXgmTCjMq7UtNRG7BWxqisD/Nj563fTn1ngjTGhlTdnUrLzM7flsnIYFa0TfZOx9xIhdeJXkeV4qaOlFdQqJREz4/ORKLVMYJJu/rbReOW9q5+vSI6IfmrOevtPUNBHIpYI0rgUsAwI9ks+mLm5dXI8jdqnj0B1KimmHmzd5cWcOaAroyufGCtPdkc4UDOzvzaf1sl+JtKl+BZ8DD0u8GbPA4R3PdyHzF3iO91k0qic/QF/vbjofAEAAA=\"}!../../../../../node_modules/.pnpm/@vanilla-extract+webpack-plugin@2.3.16_@types+node@20.14.9_babel-plugin-macros@3.1.0_webpack@_a4wh3t7jwjopzbsr5zdon56pg4/node_modules/@vanilla-extract/webpack-plugin/extracted.js"
export var BannerImageColumnStyle = '_9jr3nb6';
export var BannerTextColumnBoxStyle = '_9jr3nb4';
export var ShopfrontBannerTextColumnBoxStyle = '_9jr3nb5';
export var VerticalDivider = '_9jr3nb8';
export var VerticalDividerText = '_9jr3nba';
export var VerticalDividerWrapper = '_9jr3nb7';
export var VerticalLine = '_9jr3nb9';
export var bannerContainer = '_9jr3nb0';
export var headerImageStyle = '_9jr3nb2';
export var headerImageStyleEnlarged = '_9jr3nb1';
export var tertiaryImageStyle = '_9jr3nb3';